
import MetamaskIcon from '../images/metamask.png'
import WalletConnectIcon from '../images/wallet-connect-icon.svg'
import CoinbaseWalletIcon from '../images/coinbase-wallet-icon.svg'
import { Connector } from '@web3-react/types'
import { coinbaseWallet, injected, Wallet, walletConnect } from './connectors'
import OKXIcon from '../images/okx.png'
import BigkeepIcon from '../images/bitkeep.svg'

export interface WalletInfo {
  connector?: Connector
  wallet?: Wallet
  name: string
  iconName: string
  description: string
  href: string | null
  mobile: boolean
}

// 支持的钱包
export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: injected,
    wallet: Wallet.INJECTED,
    name: 'MetaMask',
    iconName: MetamaskIcon,
    description: 'Easy-to-use browser extension.',
    href: null,
    mobile: false,
  },
  WALLET_CONNECT: {
    connector: walletConnect,
    wallet: Wallet.WALLET_CONNECT,
    name: 'WalletConnect',
    iconName: WalletConnectIcon,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    mobile: true
  },
  COINBASE_WALLET: {
    connector: coinbaseWallet,
    wallet: Wallet.COINBASE_WALLET,
    name: 'Coinbase Wallet',
    iconName: CoinbaseWalletIcon,
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    mobile: true
  },
  OKX: {
    connector: injected,
    wallet: Wallet.INJECTED,
    name: 'OKX Wallet',
    iconName: OKXIcon,
    description: 'One interoperable wallet for all your Web3 needs.',
    href: null,
    mobile: false,
  },
  BITKEEP: {
    connector: injected,
    wallet: Wallet.INJECTED,
    name: 'BitKeep',
    iconName: BigkeepIcon,
    description: 'A secure blockchain wallet that supports: BTC, ETH, BNB, TRX, DApp, DeFi, and more.',
    href: null,
    mobile: false,
  },
}

// 默认的钱包
export const DEFAULT_WALLET = SUPPORTED_WALLETS.METAMASK
export const isOKXWallet = typeof window.okxwallet !== 'undefined' && window.okxwallet.isOkxWallet
export const isBitkeep = typeof window.bitkeep !== 'undefined' &&  window.bitkeep.ethereum