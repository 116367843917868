import React, { PropsWithChildren, useState } from 'react'
import { Tooltip as MTooltip, TooltipProps } from '@mui/material'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useAppSelector } from '../../helpers/hooks'

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          color: '#666666',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 2px 8px 0px rgba(153,153,153,1)',
        },
      },
    },
  },
})
const dashboardTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '10px',
          paddingTop: '10px',
          paddingLeft: '15px',
          paddingRight: '15px',
          fontSize: '16px',
          color: '#000000',
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
        },
        arrow: {
          color: '#FFFFFF',
        },
        tooltipPlacementBottom: {
          top: '0px'
        }
      },
    },
  },
})


interface Iprop {
  text: string
  children: React.ReactElement
  placement?: TooltipProps['placement']
  arrow?: boolean
  isDashboard?: boolean
}

const Tooltip: React.FC<PropsWithChildren<Iprop>> = ({
    children,
    text,
    arrow,
    isDashboard = false,
    placement = 'top'
  }) => {
  const [open, setOpen] = useState(false)
  const isDesktop = useAppSelector(state => state.app.isDesktop)

  const handleTooltipClose = () => {
    setOpen(false)
  }
  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={isDashboard ? dashboardTheme : theme}>
        {isDesktop && (
          <MTooltip title={text} placement={placement} arrow={arrow} >
            {children}
          </MTooltip>
        )}
        {!isDesktop && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <MTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={text}
              >
                <div onClick={handleTooltipOpen}>{children}</div>
              </MTooltip>
            </div>
          </ClickAwayListener>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default Tooltip
