import { Button as MButton } from '@mui/material'
import styled from 'styled-components'
import { defaultTheme, flexCenter, primaryLinear } from '../../style'
import ARROW from './arrow.svg'

export const ButtonWrapper = styled(MButton)`
  &.MuiButton-contained {
    height: 56px;
    border-radius: 8px;
    font-size: ${defaultTheme.fontNormal};
    background: ${prop => prop.theme.darkMode ? '#fff' : '#000000'};
    color: ${prop => prop.theme.darkMode ? '#000' : '#fff'};
    padding: 8px 24px;
    text-transform: none;
    width: ${props => (props.fullWidth ? '100%' : 'fit-content')};
    :hover {
      opacity: 0.9;
    }
    &.Mui-disabled {
      background: ${prop =>
        prop.theme.darkMode
          ? 'linear-gradient(89.96deg, rgba(78, 198, 252, 0.5) -7.1%, rgba(25, 19, 170, 0.5) 105.76%)'
          : '#656565'};
      font-size: ${prop => prop.theme.fontSmall};
      color: ${defaultTheme.grey4};
    }
  }

  &.MuiButton-outlined {
    background-color: ${props => props.theme.grey1};
    color: ${props => props.theme.grey1};
    border-color: ${props => props.theme.grey1};
    border-radius: 180px;
    font-weight: 400;
    text-transform: none;
    font-size: 14px;
    line-height: 22px;
  }
`
const ButtonPrimary = styled(MButton)`
  &.MuiButton-contained {
    height: 64px;
    ${prop =>
      prop.theme.darkMode
        ? `
        background-color: #19191b;
        border: 1px solid transparent;
        border-image: linear-gradient(89.96deg, #4ec6fc -7.1%, #1913aa 105.76%) 1;
        border-image-slice: 10;
        clip-path: inset(0 round 3px);
    `
        : `
      border: 1px solid #656565;
      border-radius: 8px;
      background-color: #ffffff;
    `}
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    color: ${prop => (prop.theme.darkMode ? 'rgba(255, 255, 255, 0.8)' : '#333333')};
    text-transform: none;

    :hover {
      ${prop =>
        prop.theme.darkMode &&
        `background-image: linear-gradient(89.96deg, #34769d -7.1%, #211c9f 105.76%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        `};

      text-decoration: underline;
    }
    &.Mui-disabled {
      background-image: linear-gradient(89.96deg, #34769d -7.1%, #211c9f 105.76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      color: ${defaultTheme.grey4};
    }
  }

  &.MuiButton-outlined {
    background-color: ${props => props.theme.grey1};
    color: ${props => props.theme.grey1};
    border-color: ${props => props.theme.grey1};
    border-radius: 180px;
    font-weight: 400;
    text-transform: none;
    font-size: 16px;
    line-height: 22px;
  }
`
const AwesomeButton = styled.div`
  position: relative;
  font-size: 16px;
  border: 1px solid #666;
  ${flexCenter};
  border-radius: 8px;
  padding: 19px 66px 19px 49px;
  width: fit-content;
  cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
  &.small {
    padding: 14px 36px 14px 26px;
    font-size: ${prop => prop.theme.fontSmaller};
    .btn-icon {
      right: 16px;
      top: 16px;
    }
  }
  &.outlined {
    background-color: #000;
  }
  .btn-text {
    color: #fff;
  }
  :hover {
    border: 1px solid transparent;
    background-image: linear-gradient(to right, #222, #222),
      linear-gradient(90deg, #ffb198, #fdf515);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    .btn-text {
      background: ${primaryLinear};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .bnt-bg {
      background: ${primaryLinear};
      opacity: 0.15;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
    }
  }
  .btn-icon {
    position: absolute;
    right: 30px;
    top: 22px;
    width: 12px;
    height: 12px;
    z-index: 3;
  }
`
type ButtonProps = {
  disabled?: boolean
  primary?: boolean
  width?: string
  height?: string
  text: JSX.Element | string // 带图片的button传入jsx
  size?: 'small' | 'large' | 'medium'
  variant?: 'text' | 'outlined' | 'contained'
  fullWidth?: boolean // 是否根据父元素填充宽度
  awesomeButton?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button: React.FC<ButtonProps> = ({
  disabled,
  primary,
  text,
  size = 'medium',
  awesomeButton,
  variant = 'contained',
  onClick,
  style,
  fullWidth = false,
}) => {
  const handleClick = (evt: any) => {
    if (onClick && !disabled) {
      onClick(evt)
    }
  }
  if (awesomeButton) {
    return (
      <AwesomeButton className={`${size} ${variant}`}>
        <div className="bnt-bg" />
        <div className="btn-text">{text}</div>
        <img className="btn-icon" src={ARROW} />
      </AwesomeButton>
    )
  }
  return (
    <>
      {primary ? (
        <ButtonPrimary
          style={style}
          className={size}
          disabled={disabled}
          variant={variant}
          onClick={handleClick}
          size={size}
        >
          {text}
        </ButtonPrimary>
      ) : (
        <ButtonWrapper
          style={style}
          className={size}
          disabled={disabled}
          variant={variant}
          onClick={handleClick}
          size={size}
          fullWidth={fullWidth}
        >
          {text}
        </ButtonWrapper>
      )}
    </>
  )
}

export default Button
