import styled from 'styled-components'
import CLOSE_ICON from './images/close.png'
import CLOSE_DARK_ICON from './images/closeDark.png'

const Wrapper = styled.img`
  width: 16px;
  height: 16px;
  content: url(${prop => (prop.theme.darkMode ? CLOSE_DARK_ICON : CLOSE_ICON)});
`

export default Wrapper
