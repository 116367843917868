
import { createSlice } from '@reduxjs/toolkit'
import { Wallet } from '../web3/connectors'

interface UserState {
  // We want the user to be able to define which wallet they want to use, even if there are multiple connected wallets via web3-react.
  // If a user had previously connected a wallet but didn't have a wallet override set (because they connected prior to this field being added),
  // we want to handle that case by backfilling them manually. Once we backfill, we set the backfilled field to `true`.
  // After some period of time, our active users will have this property set so we can likely remove the backfilling logic.
  selectedWalletBackfilled: boolean
  selectedWallet?: Wallet
  slidevisiable: boolean
  slideTitle: string
  isDesktop: boolean
  lastTimestamp: number
  subTab: string
}
export const DESKTOP_WIDTH = 1024
export let isDesktop = false
if (typeof document !== 'undefined') {
  if (window.innerWidth >= DESKTOP_WIDTH) {
    isDesktop = true
  }
}
const handleView = (desktopValue: boolean) => {
   // 如果是尺寸的设计稿在这里修改
   const WIDTH = 750
   const IPHONE = 375
    // 设置html标签的fontSize
    document.documentElement.style.fontSize = desktopValue ? '16px' : `${100 * IPHONE / WIDTH}px`
}
export const BRIDGE_NFT = 'bridgeNFT'
export const BRIDGE_TOKEN = 'bridgeToken'

const initialState: UserState = {
  selectedWallet: undefined,
  selectedWalletBackfilled: false,
  slidevisiable: false,
  slideTitle: '',
  isDesktop,
  lastTimestamp: 0,
  subTab: BRIDGE_NFT,
}
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateSelectedWallet(state, { payload: { wallet } }) {
      state.selectedWallet = wallet
      state.selectedWalletBackfilled = true
      state.lastTimestamp = new Date().getTime()
    },
    updateSlidevisiable(state, { payload: { slidevisiable } }) {
      state.slidevisiable = slidevisiable
    },
    updateDesktop(state, { payload: { desktopValue } }) {
      handleView(desktopValue)
      state.isDesktop = desktopValue
    },
    updateSlideTitle(state, { payload: { slideTitle } }) {
      state.slideTitle = slideTitle
    },
    changeSubTab(state, { payload: { subTab } }) {
      state.subTab = subTab
    },
  },
})

export const {
  updateSelectedWallet,
  updateSlidevisiable,
  updateDesktop,
  updateSlideTitle,
  changeSubTab,
} = appSlice.actions
export default appSlice.reducer
