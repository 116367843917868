import styled from 'styled-components'
import { flexCenter } from '../../style'
import NavigationButton from '../NavigationButton'
import PC_LOGO_DARK from './images/logo-dark.png'
import PC_LOGO from './images/logo.png'
import Wallet from '../Wallet'
import { Link } from 'react-router-dom'
import ThemeSwitchIcon from './ThemeSwitchIcon'
import { useAppDispatch, useAppSelector, useCurrentPath, usePath } from '../../helpers/hooks'
import HomeTab from './HomeTab/HomeTab'
import Button from '../Button/Button'
import { handleEvent } from '../../Reporter'
import Tooltip from '../Tooltip/Tooltip'
import { BRIDGE_NFT, BRIDGE_TOKEN, changeSubTab } from '../../redux/reducer'
import { useEffect } from 'react'

const HeaderWrapper = styled.div<{ isHome?: boolean }>`
  ${flexCenter};
  flex-direction: row;
  position:absolute;
  top: 0;
  z-index: 10;
  justify-content: space-between;
  width: 100%;
  height: ${prop => (prop.theme.isDesktop ? '88px' : '0.88rem')};
  padding: ${prop => (prop.theme.isDesktop ? `0 40px` : `0 0.31rem`)};
  .button-primary {
    ${flexCenter};
    width: 148px;
    padding-top: 12px;
    padding-bottom: 10px;
    border-radius: 8px;
    color: #fff;
    border: 1px solid #fff;
    font-size: ${prop => (prop.theme.isDesktop ? '16px' : '0.26rem')};
    font-family: 'GSMedium';
    margin-right: ${prop => (prop.theme.isDesktop ? '20px' : '0.22rem')};
  }
`

const Logo = styled.img<{ isHome?: boolean }>`
  width: ${prop => prop.theme.isDesktop ? '157px' : '1.57rem'};
  height: auto;
  content: url(${props => (props.theme.darkMode ? PC_LOGO_DARK : PC_LOGO)});
  ${props => props.isHome && `
  content: url(${PC_LOGO_DARK})
  `}
`

const RightWrapper = styled.div`
  ${flexCenter};
  flex-direction: row;
  .center {
    position: absolute;
    left: calc(50% - 148px);
    display: flex;
    flex-direction: row;
  }
`

const Header: React.FC = () => {
  const { isHome, isMarket } = usePath()
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const currentPath = useCurrentPath()
  const subTab = useAppSelector(state => state.app.subTab) || BRIDGE_NFT
  const dispatch = useAppDispatch()
  
  const handleChangeSubTab = (currnet: string) => {
    dispatch(changeSubTab({ subTab: currnet }))
  }

  const HeaderContainer = () => {
    return (
      <HeaderWrapper isHome={isHome}>
        <Link to="/"><Logo isHome={isHome}/></Link>
        <RightWrapper>
          {isDesktop && !isHome && !isMarket && (
            <div className='center'>
              <Link to="bridge" >
                <NavigationButton onClick={() => handleChangeSubTab(BRIDGE_TOKEN)}
                  isActive={currentPath === '/bridge' && subTab === BRIDGE_TOKEN}
                  >Token</NavigationButton>
              </Link>
              <Link to="bridge" >
                <NavigationButton onClick={() => handleChangeSubTab(BRIDGE_NFT)}
                  isActive={currentPath === '/bridge' && subTab === BRIDGE_NFT}
                  >NFT</NavigationButton>
              </Link>
              <Link to="mint">
                <NavigationButton isActive={currentPath === '/mint'}>Mint</NavigationButton>
              </Link>
            </div>
          )}
          {isDesktop && (isHome || isMarket) && <HomeTab />}
          {isHome && <Tooltip text="coming soon" placement="bottom" arrow isDashboard >
            <a className='button-primary' onClick={() => handleEvent('Nav_TRAIDO')}>Buy $TRA</a>
          </Tooltip> }
          <Wallet />
          {!isHome  && <ThemeSwitchIcon />}
        </RightWrapper>
      </HeaderWrapper>
    )
  }

  return <HeaderContainer />
}

export default Header
