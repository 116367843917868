import styled from 'styled-components'
import { flexCenter, flexRow } from '../../style'
import H5Foot from '../H5Foot'
import CommunityLinkGroup from '../CommunityLinkGroup'
import { ThemeContext, useAppSelector, usePath } from '../../helpers/hooks'
import LARYERZERO from './layerzero.svg'
import LARYERZERO_DARK from './layerzero-dark.svg'
import { useContext } from 'react'

const Wrapper = styled.div`
  ${flexRow}
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 90px;
  padding: 0 120px;
  background-color: ${props => props.theme.darkMode ? '#050506' : '#F0F0F0'};
  color: ${props => !props.theme.darkMode ? '#000' : '#F0F0F0'};
  .link {
    ${flexCenter};
    ${flexRow};
    font-size: ${prop => prop.theme.fontSmall};
    font-family: SFRegular;
  }
  .logo {
    width: 120px;
    height: auto;
  }
  ${prop => !prop.theme.isDesktop && `
    flex-direction: column;
    padding: 0;
    height: 1.8rem;
    width: 100%;
    justify-content: center;
    bottom: 50px;
  ` }
`

const Foot = () => {
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const { isHome } = usePath()
  const { darkMode } = useContext(ThemeContext)

  if (isHome) {
    return (<>
      <Wrapper>
        <a
          href='https://layerzero.network/'
          className='link'
          target="_blank"
          rel="noreferrer">
          Powered by
          <img className='logo' src={darkMode ? LARYERZERO_DARK : LARYERZERO} />
        </a>
        <CommunityLinkGroup />
      </Wrapper>
      {!isDesktop && <H5Foot />}
    </>)
  }
  if (isDesktop) {
    return (
      <Wrapper>

        <a href='https://layerzero.network/'
          className='link'
          target="_blank"
          rel="noreferrer">
             Powered by
          <img className='logo' src={darkMode ? LARYERZERO_DARK : LARYERZERO} />
        </a>
        <CommunityLinkGroup />
      </Wrapper>
    )
  }
  return <H5Foot />
}

export default Foot
