import React from 'react'
import { RouteObject } from 'react-router-dom'
import Home from './pages/Home'
import BgConntainer from './components/BgConntainer'
import Market from './pages/Market'

const lazyFactory = (LazyComponent: React.LazyExoticComponent<React.FC<{}>>) => {
  return (
    <React.Suspense fallback={null}>
      <LazyComponent />
    </React.Suspense>
  )
}

const getBg = (element: JSX.Element) => <BgConntainer>{element}</BgConntainer>
// dynamic import
const Main = React.lazy(() => import('./pages/Main'))
const History = React.lazy(() => import('./pages/History'))
const Mint = React.lazy(() => import('./pages/Mint'))

const LazyMain = getBg(lazyFactory(Main))
const LazyHistroy = getBg(lazyFactory(History))
const LazyMint = getBg(lazyFactory(Mint))

export const routes: RouteObject[] = [
  { path: '/', element: <Home /> },
  { path: '/bridge', element: LazyMain },
  { path: '/mint', element: LazyMint },
  { path: '/history', element: LazyHistroy },
  { path: '/market', element: <Market/> },
  { path: '*', element: <Home /> },
]
export default routes
