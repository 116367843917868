import { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext, useCurrentPath } from '../../helpers/hooks'
import { useNavigate } from 'react-router-dom'

import { flexColumn, flexRow } from '../../style'

import HISTORY from './images/history.svg'
import HISTORY_SELECT from './images/history-selected.svg'
import HISTORY_DARK_SELECT from './images/history-selected-dark.svg'

import TRANSFER from './images/transfer.svg'
import TRANSFER_SELECT from './images/transfer-selected.svg'
import TRANSFER_DARK_SELECT from './images/transfer-selected-dark.svg'

import MINT from './images/mint.svg'
import MINT_SELECT from './images/mint-selected.svg'
import MINT_DARK_SELECT from './images/mint-selected-dark.svg'


const Wrapper = styled.div`
  ${flexRow}
  position:fixed;
  bottom: 0;
  z-index: 999;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 0.98rem;
  background: ${prop => (prop.theme.darkMode ? '#232323' : '#FFFFFF')};
  ${prop => prop.theme.darkMode && `border-top: 1px solid #333333`};
`

const IconStyle = styled.div<{ selectStatus: boolean }>`
  ${flexColumn}
  align-items: center;
  justify-content: center;
  width: 2.26rem;
  height: 100%;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 0.2rem;
  color: ${({ selectStatus, theme }) => (selectStatus ? theme.grey1 : theme.grey4)};

  img {
    width: ${({selectStatus}) => selectStatus ? '0.27rem' : '0.35rem'};
    margin-bottom: 0.1rem;
  }
`

interface iconItemType {
  icon: string
  iconSelect: string
  iconDark: string
  iconDarkSelect: string
  path: string
}

const iconMap: Record<string, iconItemType> = {
  bridge: {
    icon: TRANSFER,
    iconSelect: TRANSFER_SELECT,
    iconDark: TRANSFER,
    iconDarkSelect: TRANSFER_DARK_SELECT,
    path: '/bridge',
  },
  mint: {
    icon: MINT,
    iconSelect: MINT_SELECT,
    iconDark: MINT,
    iconDarkSelect: MINT_DARK_SELECT,
    path: '/mint',
  },
  history: {
    icon: HISTORY,
    iconSelect: HISTORY_SELECT,
    iconDark: HISTORY,
    iconDarkSelect: HISTORY_DARK_SELECT,
    path: '/history',
  },
}

const H5Foot: React.FC = () => {
  const { darkMode } = useContext(ThemeContext)
  const currentPath = useCurrentPath()
  const navigate = useNavigate()

  const getImgSrc = (imgItem: iconItemType) => {
    if (darkMode) {
      return imgItem.path === currentPath ? imgItem.iconDarkSelect : imgItem.iconDark
    }
    return imgItem.path === currentPath ? imgItem.iconSelect : imgItem.icon
  }
  return (
    <Wrapper>
      {Object.keys(iconMap).map(key => (
        <IconStyle
          key={key}
          selectStatus={iconMap[key].path === currentPath}
          onClick={() => navigate(iconMap[key].path)}
        >
          <img src={getImgSrc(iconMap[key])} />
          {iconMap[key].path === currentPath && key}
        </IconStyle>
      ))}
    </Wrapper>
  )
}

export default H5Foot
