import React from 'react'
import { ToastContainer as Container } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'

const StyledContainer = styled(Container)`
  align-items: center;
  font-size: ${prop => prop.theme.fontSmall};
  .Toastify__toast {

    background: transparent;
    padding: 0;
    font-size: 16px;
    min-height: fit-content;
    .Toastify__toast-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      color: #FFFFFF;
      border-radius: 8px;
      line-height: 17px;
      padding: 8px 16px;
      background: rgba(35, 38, 47, 0.6);
    }
  }
  .Toastify--animate-icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
`
export const TOAST_CONTAINER_ID = 'toast'
// 按需处理 https://fkhadra.github.io/react-toastify/introduction
const ToastContainer: React.FC = () => {
  return (
    <StyledContainer
      enableMultiContainer
      containerId={TOAST_CONTAINER_ID}
      position="top-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={false}
    />
  )
}

export default ToastContainer
