import styled from 'styled-components'
import BG_DARK from '../../pages/Home/images/stars.svg'

const BgConntainer = styled.div`
  position: relative;
  padding-top: ${prop => (prop.theme.isDesktop ? '88px' : '0.88rem')};
  padding-bottom: ${prop => (prop.theme.isDesktop ? '' : '1.18rem')};
  width: 100%;
  min-height: ${prop => (prop.theme.isDesktop ? '900px' : '15rem')};
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ theme }) => {
    const { isDesktop, darkMode } = theme
    if (isDesktop) {
      if (darkMode) {
        return `
        background-image: url(${BG_DARK});
        background-repeat: repeat;
        background-color: #000;
        `
      } else {
        return `background-color: #F0F0F0`
      }
    }
  }};
  background-color: ${({ theme }) => {
    const { isDesktop, darkMode } = theme
    if (!isDesktop) {
      if (darkMode) {
        return '#000000'
      } else {
        return '#EBEBEB'
      }
    }
  }};
`

export default BgConntainer
