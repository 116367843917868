import { useRoutes } from 'react-router-dom'
import { darkTheme, GlobalStyle, lightTheme } from './style'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { StyledEngineProvider } from '@mui/material/styles'
import { LoadingProvider } from './components/Loading/Loading'
import { ThemeProvider } from 'styled-components'
import { ThemeContext, useAppDispatch, useAppSelector } from './helpers/hooks'
import ToastContainer from './components/Toast/ToastContainer'
import Header from './components/Header'
import routes from './routerConfig'
import MessageContainer from './components/Message/MessageContainer'
import Foot from './components/Foot'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DESKTOP_WIDTH, updateDesktop } from './redux/reducer'
import Report from './Reporter'
import {WalletProvider} from '@suiet/wallet-kit';
import '@suiet/wallet-kit/style.css';


function App() {
  const [darkMode, setDarkMode] = useState(true)
  const matchesDesktop = useMediaQuery(`(min-width: ${DESKTOP_WIDTH}px)`)
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updateDesktop({ desktopValue: matchesDesktop }))
  }, [matchesDesktop, dispatch])

  const getTheme = useCallback(() => {
    return darkMode ? { ...darkTheme, isDesktop } : { ...lightTheme, isDesktop }
  }, [darkMode, isDesktop])

  return (
    <React.StrictMode>
      <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <WalletProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={getTheme()}>
            <LoadingProvider>
              <GlobalStyle />
              <Header />
              {useRoutes(routes)}
              <Foot />
              <Report />
              <ToastContainer />
              <MessageContainer />
            </LoadingProvider>
          </ThemeProvider>
        </StyledEngineProvider>
        </WalletProvider>
      </ThemeContext.Provider>
    </React.StrictMode>
  )
}

export default App

