import { Modal as MModal } from '@mui/material'
import Fade from '@mui/material/Fade'
import styled from 'styled-components'
import { flexCenter } from '../../style'
import CloseIcon from '../CloseIcon'

const ModalBody = styled.div<{ bgUrl?: string }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${prop => (prop.theme.darkMode ? `#222222` : '#FFFFFF')};
  box-shadow: ${prop => (prop.theme.darkMode ? `0 16px 32px 0 rgba(0,0,0,0.33)` : '0 8px 16px 0 rgba(0,0,0,0.5)')};
  outline: 0;
  border-radius: 20px;
`
const ModalHeader = styled.div`
  ${flexCenter};
  align-items: center;
  position: relative;
  padding: 20px;
  font-weight: ${prop => (prop.theme.isDesktop ? 600 : 'normal')};
  font-size: ${prop => (prop.theme.isDesktop ? '24px' : '16px')};
  color: ${prop => prop.theme.grey1};
  border-bottom: 1px solid ${prop => prop.theme.darkMode ? '#333333' :  '#fff'};

  .icon {
    position: absolute;
    right: 24px;
    top: 18pxpx;
    width: 16px;
    height: 16px;
    cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
  }
  .leftContent {
    position: absolute;
    right: 24px;
    top: 18px;
  }
`

interface ModalProps {
  children: React.ReactElement
  title?: string
  open: boolean
  showCloseIcon?: boolean
  width?: number // px
  onClose: () => void
  style?: Record<any, any>
  leftContent?: React.ReactElement
  bgUrl?: string
}

const Modal: React.FC<ModalProps> = ({
  children,
  title = '',
  showCloseIcon = true,
  open,
  width = 0,
  onClose,
  style = {},
  leftContent,
  bgUrl,
}) => {
  return (
    <MModal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={open}>
        <ModalBody
          style={{ ...{ width: (width ?? 0) > 0 ? `${width}px` : 'auto' }, ...style }}
          bgUrl={bgUrl}
        >
          <ModalHeader>
            {leftContent && <div className="leftContnet">{leftContent}</div>}
            {title}
            {showCloseIcon && <CloseIcon className="icon" onClick={onClose} />}
          </ModalHeader>
          {children}
        </ModalBody>
      </Fade>
    </MModal>
  )
}

export default Modal
