import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { flexCenter } from '../../style'
import {
  switchChain,
  DEFAULT_NETWORK,
  NETWORK_CONFIG,
  isChainAllowed,
  SUPPORTED_CHAIN_IDS,
} from '../../web3/chain'
import Popover from '../Popover'
import toast from '../Toast/Toast'
import LinkIcon from './images/link.svg'
import NavigationButton from '../NavigationButton'

import { emitter, H5_MASK, RESER_STATE } from '../../helpers/emitterName'
import { useWeb3React } from '@web3-react/core'
import { useAppSelector } from '../../helpers/hooks'

const SelectorWrapper = styled(NavigationButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 12px 16px;
  margin-left: 24px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 14px;
  color: ${prop => prop.theme.grey3};
  ${prop => prop.theme.darkMode && `
    background-color: #000;
    border: 1px solid #666;
  `};
  cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
  .logo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  :hover {
    opacity: 0.9;
    ${prop => prop.theme.darkMode && `
    background-color: rgba(255,255,255,0.1);
    border: 1px solid #666;
    opacity: 1;
  `};
  }
`

const Label = `
  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    width: 280px;
    margin-top: 16px;
    cursor: ${(prop: { theme: { isDesktop: any } }) => (prop.theme.isDesktop ? 'pointer' : 'none')};
    font-weight: 500;
    font-family: MiSans-Normal;
    .logo {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
`
const PopoverContent = styled.div`
  ${flexCenter};
  padding: 16px;
  align-items: center;
  background: ${prop => (prop.theme.darkMode ? `rgb(25, 25, 25)` : '#ffffff')};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  color: ${prop => prop.theme.grey1};

  .title {
    font-size: ${prop => prop.theme.fontSmall};
    line-height: 17px;
  }
  ${Label}
`
const SelectdWrapper = styled.div`
  position: relative;
  width: 343px;
  background: rgba(0, 87, 255, 0.1);
  border-radius: 8px;
  padding: 10px 16px;
  margin-top: 10px;
  ${Label};
  .label {
    margin-top: 0;
  }
  .row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 16px;
    .link {
      width: 10px;
      height: 10px;
      cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
    }
  }
  .dot {
    position: absolute;
    top: 22px;
    right: 16px;
    width: 8px;
    height: 8px;
    background: #66b949;
    border-radius: 50%;
  }
`
const MaskContentWrapper = styled(PopoverContent)`
  position: fixed;
  left: 0;
  top: 80px;
  width: 100vw;
  border-radius: 0px 0px 16px 16px;
  box-shadow: none;
`
const NETWORK_SELECTOR = 'newworkSelector'


const support_network_arr: Record<any, any> = {}

Object.keys(NETWORK_CONFIG).forEach(key => {
  if (SUPPORTED_CHAIN_IDS.includes(Number(key))) {
    support_network_arr[key] = NETWORK_CONFIG[Number(key)]
  }
})

interface NetworkProps {
  handleNoWallet?: () => void
}
const NetworkSelector: React.FC<NetworkProps> = ({ handleNoWallet }) => {
  const { chainId, isActive, connector, account } = useWeb3React()
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const anchorElRef = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState(false)
  emitter.on(H5_MASK, (value: boolean) => {
    setShow(value)
  })
  useEffect(() => {
    if (!isDesktop) {
      emitter.emit(H5_MASK, show)
    }
  }, [show])
  const handleShow = () => {
    setShow(state => !state)
  }
  const onSelectChain = useCallback(
    (id: number) => {
      if (!isActive && handleNoWallet) {
        setShow(false)
        handleNoWallet()
        return
      }
      if (id === chainId) return
      switchChain(connector, id)
        .then(() => {
          emitter.emit(RESER_STATE)
        })
        .catch(() => {
          toast({ text: 'Something Wrong.Please try again', type: 'error' })
        })
    },
    [chainId, connector, isActive, handleNoWallet],
  )
  // 已连接的网络
  const activedNetwork = (id: number) => {
    return (
      <SelectdWrapper key={id}>
        <div className="label">
          <img className="logo" src={NETWORK_CONFIG[id].logo} />
          {NETWORK_CONFIG[id].chainName}
        </div>
        <a className="row" href={NETWORK_CONFIG[id].explorer} target="_blank" rel="noreferrer">
          <div>{NETWORK_CONFIG[id].chainName} Scan</div>
          <img className="link" src={LinkIcon} />
        </a>
        <div className="dot" />
      </SelectdWrapper>
    )
  }

  return (
    <>
      {isDesktop && (
        <SelectorWrapper
          aria-describedby={NETWORK_SELECTOR}
          aria-haspopup="true"
          ref={anchorElRef}
          onClick={handleShow}
        >
          <img className="logo"
            src={
              NETWORK_CONFIG[isChainAllowed(connector, chainId) ? chainId! : DEFAULT_NETWORK].logo
            }
          />
          {isDesktop &&
            NETWORK_CONFIG[isChainAllowed(connector, chainId) ? chainId! : DEFAULT_NETWORK]
              .chainName}
        </SelectorWrapper>
      )}

      <Popover
        open={show && isDesktop}
        id={NETWORK_SELECTOR}
        anchorEl={anchorElRef.current}
        onClose={() => setShow(false)}
      >
        <PopoverContent>
          <div className="title">Select a network</div>
          {Object.keys(support_network_arr).map(id => {
            return !!chainId && Number(chainId) === Number(id) ? (
              activedNetwork(Number(id))
            ) : (
              <div className="label" key={id} onClick={() => onSelectChain(Number(id))}>
                <img className="logo" src={NETWORK_CONFIG[Number(id)].logo} />
                {NETWORK_CONFIG[Number(id)].chainName}
              </div>
            )
          })}
        </PopoverContent>
      </Popover>
      {!isDesktop && show && (
        <MaskContentWrapper>
          <div className="title">Select a network</div>
          {Object.keys(NETWORK_CONFIG).map(id => {
            return !!chainId && Number(chainId) === Number(id) ? (
              activedNetwork(Number(id))
            ) : (
              <div className="label" key={id} onClick={() => onSelectChain(Number(id))}>
                <img className="logo" src={NETWORK_CONFIG[Number(id)].logo} />
                {NETWORK_CONFIG[Number(id)].chainName}
              </div>
            )
          })}
        </MaskContentWrapper>
      )}
    </>
  )
}

export default NetworkSelector
