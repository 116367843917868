import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Base64 } from 'js-base64'
import { v4 as uuidv4 } from 'uuid'
import { getMethod } from './http'
import { useAppSelector } from './helpers/hooks'
import { isDesktop } from './helpers/utils'

interface ReporterData {
  projectId: string
  uuid: string
  hash: string
  time: number // 访问时间（秒）
  device: string // 设备类别
  source: string // 访问源
  wallet: string // 钱包
  browser: string // 浏览器
  system: string // 操作系统
  resolution: string // 设备尺寸
  language: string // 语言
  events: Array<{
    name: string
    count: number
  }>
  pages: string[]
}
const WINDOW_URL = window.location.href
// todo
const REQUEST_URL = 'https://trantor.network/v1/api'
// const REQUEST_URL = 'https://gotest.finance/api/v1/data'
// const REQUEST_URL = 'http://192.168.3.57:3000/api/v1/data'

export const EVENT = 'events'
const SRC = 'source'
const { userAgent } = navigator

export function randomString(len ?: number) {
    len = len || 21
    const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    const maxPos = $chars.length
    let rs = ''
    for (let i = 0; i < len; i++) {
        rs += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return rs
}
export function encrypt(context: string) {
    for(let i = 0; i < 2; i++) {
        context = Base64.encode(context)
    }
    return randomString(10) + context
}


const getDevice = () => {
  const CategoryArr = [
    {
      name: 'Mobile',
      it: userAgent.indexOf('iPad') > -1 === false && userAgent.match(/Mobile/),
    },
    {
      name: 'Tablet',
      it: [480, 600, 720].includes(window.screen.width),
    },
    {
      name: 'Desktop',
      it: isDesktop,
    },
  ]
  for (const item of CategoryArr) {
    if (item.it) {
      return item.name
    }
  }
  return 'Else'
}
const getSystem = () => {
  const OSArr = [
    {
      name: 'Windows',
      it: !!userAgent.match(/compatible/i) || userAgent.match(/Windows/i),
    },
    {
      name: 'MacOS',
      it: !!userAgent.match(/Macintosh/i) || userAgent.match(/MacIntel/i),
    },
    {
      name: 'Ios',
      it: !!userAgent.match(/iphone/i) || userAgent.match(/Ipad/i),
    },
    {
      name: 'Android',
      it: !!userAgent.match(/android/i),
    },
    {
      name: 'Ubuntu',
      it: !!userAgent.match(/Ubuntu/i),
    },
    {
      name: 'Linux',
      it: !!userAgent.match(/Linux/i),
    },
  ]
  for (const item of OSArr) {
    if (item.it) {
      return item.name
    }
  }
  return 'Other'
}
const getBrowsers = () => {
  const n: any = window.navigator
  const bwsArr = [
    {
      name: 'sgssapp',
      it: /sogousearch/i.test(userAgent),
    },
    {
      name: 'wechat',
      it: /MicroMessenger/i.test(userAgent),
    },
    {
      name: 'weibo',
      it: !!userAgent.match(/Weibo/i),
    },
    {
      name: 'uc',
      it: !!userAgent.match(/UCBrowser/i) || userAgent.indexOf(' UBrowser') > -1,
    },
    {
      name: 'Brave',
      it: !!(
        !('mozInnerScreenX' in window) &&
        'chrome' in window &&
        'webkitStorageInfo' in window &&
        'brave' in n &&
        'isBrave' in n.brave
      ),
    },
    {
      name: 'sogou',
      it: userAgent.indexOf('MetaSr') > -1 || userAgent.indexOf('Sogou') > -1,
    },
    {
      name: 'xiaomi',
      it: userAgent.indexOf('MiuiBrowser') > -1,
    },
    {
      name: 'baidu',
      it: userAgent.indexOf('Baidu') > -1 || userAgent.indexOf('BIDUBrowser') > -1,
    },
    {
      name: '360',
      it: userAgent.indexOf('360EE') > -1 || userAgent.indexOf('360SE') > -1,
    },
    {
      name: '2345',
      it: userAgent.indexOf('2345Explorer') > -1,
    },
    {
      name: 'edge',
      it: userAgent.indexOf('Edge') > -1,
    },
    {
      name: 'ie11',
      it: userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1,
    },
    {
      name: 'ie',
      it: userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1,
    },
    {
      name: 'firefox',
      it: userAgent.indexOf('Firefox') > -1,
    },
    {
      name: 'safari',
      it: userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1,
    },
    {
      name: 'qqbrowser',
      it: userAgent.indexOf('MQQBrowser') > -1 && userAgent.indexOf(' QQ') === -1,
    },
    {
      name: 'qq',
      it: userAgent.indexOf('QQ') > -1,
    },
    {
      name: 'chrome',
      it: userAgent.indexOf('Chrome') > -1 || userAgent.indexOf('CriOS') > -1,
    },
    {
      name: 'opera',
      it: userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1,
    },
  ]
  for (const item of bwsArr) {
    if (item.it) {
      return item.name
    }
  }
  return 'Other'
}
const handleCookies = () => {
  try {
    if (!document.cookie.includes('uuid')) {
      // 24小时过期
      const TIME = new Date(new Date().getTime() + 1000 * 60 * 60 * 24).toUTCString()
      document.cookie = `uuid=${encodeURIComponent(uuidv4().replace(/-/g, ''))}; expires=${TIME}`
    }
  } catch (e) {
    console.error(e)
  }
}
const reportEvent = (data: any) => {
  handleCookies()
  const formData = new FormData()
  const initData = {
    device: getDevice(),
    time: 0,
    source: '',
    wallet: '',
    browser: '',
    system: '',
    resolution: ``,
    language: '',
    hash: encrypt(randomString()) ,
    pages: [],
    uuid: getUuid(),
    events: [],
    projectId: '',
  }
  formData.append('token', encrypt(JSON.stringify({ ...initData, ...data })))
  navigator.sendBeacon(`${REQUEST_URL}`, formData)
}

export const handleEvent = (key: string) => {
  const data = JSON.parse(localStorage.getItem(EVENT)!) || {}
  localStorage.setItem(
    EVENT,
    JSON.stringify({
      ...data,
      [key]: data[key] + 1 || 1,
    }),
  )
  if (!isDesktop) {
    // 移动端监听不到sendBeacon 事件，所以每次点击的时候都会上报一次数据
    reportEvent({
      events: [
        {
          name: key,
          count: 1,
        },
      ],
    })
  }
}

const getUuid = () => {
  return document.cookie.split('uuid=')[1].split(';')[0]
  ? document.cookie.split('uuid=')[1].split(';')[0]
  : `${new Date().getTime()}`
}

const getSource = (): string => {
  const index = WINDOW_URL.lastIndexOf('/')
  const str = localStorage.getItem(SRC)
    ? JSON.parse(localStorage.getItem(SRC)!)
    : WINDOW_URL.substring(index + 1, WINDOW_URL.length)
  const sourceArr = [
    {
      name: 'Twitter',
      it: !!str.match(/Twitter/i),
    },
    {
      name: 'Telegram',
      it: !!str.match(/Telegram/i),
    },
    {
      name: 'Discord',
      it: !!str.match(/Discord/i),
    },
    {
      name: 'Mirror',
      it: !!str.match(/Mirror/i),
    },
    {
      name: 'Blockbeats',
      it: !!str.match(/Blockbeats/i),
    },
  ]
  for (const item of sourceArr) {
    if (item.it) {
      window.localStorage.removeItem(SRC)
      return item.name
    }
  }

  if (str.match(/src/i)) {
    window.localStorage.removeItem(SRC)
    return str.split('=')[1] || 'direct'
  }
  window.localStorage.removeItem(SRC)
  return 'direct'
}

// 获取手机端钱包软件
const getMobilePocket = () => {
  if (!isDesktop) {
    const startIndex = userAgent.lastIndexOf(' ')
    const application = userAgent.substring(startIndex + 1)
    return application
  }
  return 'direct'
}


const routerObj: {[key: string]: string} = {
  '/': 'homepage',
  '/market': 'market',
  'bridge': 'bridge',
  '/mint': 'mint',
  '/history': 'history',
}
const getRouterKey = (): string => {
  let res = ''
  for (const [value, key] of Object.entries(routerObj)) {
    if (location.pathname.includes(value)) {
     res = key
    }
  }
  return res
}

export default function Report() {
  const location = useLocation()
  const pageDataRef = useRef<any>([])
  const mobile = useAppSelector(state => !state.app.isDesktop)

  const getUserAgent = (data: any): ReporterData => {

    let pages: ReporterData["pages"] = []
    if (data.length) {
      pages = data.map((item: any) => {
        return item
      })
    } else {
      pages = [getRouterKey()]
    }

    const events: any = []
    if (localStorage.getItem(EVENT)) {
      const eventData = JSON.parse(localStorage.getItem(EVENT)!)
      for (const item of Object.keys(eventData)) {
        events.push({
          name: item,
          count: eventData[item],
        })
      }
    }
    return {
      device: getDevice(),
      time: Math.floor(window.performance.timeOrigin / 1000),
      source: `${getSource()}`,
      wallet: getMobilePocket(),
      browser: getBrowsers(),
      system: getSystem(),
      resolution: `${window.screen.width}×${window.screen.height}`,
      language: navigator.language,
      hash: encrypt(randomString()) ,
      pages,
      uuid: getUuid(),
      events,
      projectId: '',
    }
  }

  const reload = () => {
    const { pathname, search, origin } = window.location
    if (search) {
      localStorage.setItem(SRC, JSON.stringify(search))
      window.location.replace(`${origin}${pathname}`)
    }
  }

  useEffect(() => {
    reload()
    window.localStorage.removeItem(EVENT)
    const listenerEvent = () => {
      reportData(`${REQUEST_URL}`)
    }
    if (mobile) {
      listenerEvent()
    } else {
      window.onbeforeunload = () => {
        listenerEvent()
      }
    }
  }, [])

  useEffect(() => {
    if (mobile) {
      if (pageDataRef.current.length) {
        const pages = [
          getRouterKey()
        ]
        reportEvent({ pages, source: getSource() })
      }
    }
    if (!pageDataRef.current.includes(getRouterKey())) {
      pageDataRef.current = [...pageDataRef.current, getRouterKey()]
    }
  }, [location.pathname])

  const reportData = (url: string) => {
    handleCookies()
    const formData = new FormData()
    // localStorage.setItem('item', JSON.stringify(getUserAgent(pageDataRef.current)))
    formData.append('token', encrypt(JSON.stringify(getUserAgent(pageDataRef.current))))
    navigator.sendBeacon(url, formData)
  }

  return <></>
}