import styled from "styled-components"
import { flexCenter } from "../../style"
import { Link } from 'react-router-dom'
import PLANET_1 from './images/planet-pic-1.png'
import PLANET_2 from './images/planet-pic-2.png'
import PLANET_3 from './images/planet-pic-3.png'
import PLANET_4 from './images/planet-pic-4.png'
import BG_IMG from './images/stars.svg'
import BG_HEAD from './images/homepage-headbg.jpeg'
import LINE_DARK from './images/line-dark.png'
import LINE_LIGHT from './images/line-light.png'
import { ThemeContext, useAppSelector } from "../../helpers/hooks"
import { useContext } from "react"
import Tooltip from "../../components/Tooltip"
import { handleEvent } from "../../Reporter"

const Wrapper = styled.div`
  background-size: auto;
  background-repeat: repeat;
  background-position-x: center;
  background-color: ${prop => (prop.theme.darkMode ? '#050506' : '#F0F0F0')};
  ${prop => prop.theme.darkMode && `
  background-image: url(${BG_IMG});
  `}
  font-family: 'SFRegular';
  align-items: center;
  min-width: ${prop => (prop.theme.isDesktop ? '1024px' : '100%')};
  padding-top: ${prop => (prop.theme.isDesktop ? '88px' : '0.5rem')};
  padding-bottom: ${prop => (prop.theme.isDesktop ? '238px' : '3.2rem')};
  .head {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    overflow: hidden;
    .head-img {
      width:  ${prop => (prop.theme.isDesktop ? '100%' : '1440px')};
      height: auto;
      ${prop => !prop.theme.isDesktop && `
        margin-left: calc(50% - 720px);
      `}
    }
  }
  .title {
    color: #fff;
    font-size: ${prop => (prop.theme.isDesktop ? '64px' : '0.72rem')};
    text-align: center;
    font-weight: 700;
    font-family: GSBold;
    line-height: ${prop => (prop.theme.isDesktop ? '77px' : '0.86rem')};
    margin-top: ${prop => (prop.theme.isDesktop ? '110px' : '1.75rem')};
    z-index: 1;
  }
  .button-primary {
    ${flexCenter};
    width: 148px;
    padding-top: 12px;
    padding-bottom: 10px;
    border-radius: 8px;
    color: #fff;
    border: 1px solid #fff;
    font-size: ${prop => (prop.theme.isDesktop ? '16px' : '0.26rem')};
    font-family: 'GSMedium';
  }
  .button-wrapper {
    ${flexCenter};
    flex-direction: row;
    margin-top:  ${prop => (prop.theme.isDesktop ? '110px' : '6rem')};
    font-family: 'GSMedium';
    font-size: ${prop => (prop.theme.isDesktop ? '16px' : '0.26rem')};
    z-index: 1;
    .defalut {
      ${flexCenter};
      position: relative;
      width: 148px;
      padding-top: 12px;
      padding-bottom: 10px;
      color: #000;
      border-radius: 8px;
      background-color: #fff;
      margin-right: 10px;
      overflow: hidden;
      :hover {
        .bg {
          transition: 0.3s ease-out all;
          right: 0px;
        }
      }
      .bg {
        position: absolute;
        ${flexCenter};
        right: -149px;
        top: 0;
        width: 148px;
        padding-top: 12px;
        padding-bottom: 10px;
        background-color: #fff;
        color: #000;
      }
    }
  }
  .se-title {
    width: ${prop => (prop.theme.isDesktop ? '70%' : '100%')};
    font-size: ${prop => (prop.theme.isDesktop ? '16px' : '0.24rem')};
    color: ${prop => prop.theme.grey2};
    margin-top: ${prop => (prop.theme.isDesktop ? '27vw' : '5.09rem')};
    margin-bottom: ${prop => (prop.theme.isDesktop ? '1.68rem' : '0.2rem')};
    z-index: 1;
    font-family: 'GSRegular';
    font-weight: 700;
    ${prop => !prop.theme.isDesktop && `
      padding-left: 0.4rem;
    `};
  }
  .desc {
    width: ${prop => (prop.theme.isDesktop ? '71%' : '100%')};
    color: ${prop => prop.theme.grey1};
    text-align: left;
    font-weight: 700;
    line-height: ${prop => (prop.theme.isDesktop ? '48px' : '0.68rem')};
    font-size: ${prop => (prop.theme.isDesktop ? '36px' : '0.72rem')};
    font-family: GSBold;
    z-index: 1;
    margin-bottom: ${prop => (prop.theme.isDesktop ? '2rem' : '0.2rem')};
    ${prop => !prop.theme.isDesktop && `
      padding: 0 0.4rem;
      text-align: left;
  `}
  }
  .se-desc {
    width: ${prop => (prop.theme.isDesktop ? '70%' : '100%')};
    color: ${prop => prop.theme.grey4};
    font-size: ${prop => (prop.theme.isDesktop ? '20px' : '0.4rem')};
    line-height: ${prop => (prop.theme.isDesktop ? '32px' : '0.64rem')};
    font-family: GSRegular;
    ${prop => !prop.theme.isDesktop && `
    padding: 0 0.4rem;
    margin-bottom: 3.6rem;
  `}
  }
  .row {
    ${flexCenter};
    flex-direction: ${prop => (prop.theme.isDesktop ? 'row' : 'column')};
    margin-top: ${prop => (prop.theme.isDesktop ? '125px' : '1.25rem')};
    width: ${prop => (prop.theme.isDesktop ? '70%' : '100%')};
    .planet-1 {
      width: ${prop => (prop.theme.isDesktop ? '380px' : 'calc(100% - 0.8rem)')};
      height: ${prop => (prop.theme.isDesktop ? '380px' : 'calc(100% - 0.8rem)')};
      margin-left: ${prop => (prop.theme.isDesktop ? 'auto' : '0')};
      margin-top: ${prop => (prop.theme.isDesktop ? '0' : '0.52rem ')};
    }
    .planet-2 {
      width: ${prop => (prop.theme.isDesktop ? '380px' : 'calc(100% - 0.8rem)')};
      height: ${prop => (prop.theme.isDesktop ? '380px' : 'calc(100% - 0.8rem)')};
      margin-right: ${prop => (prop.theme.isDesktop ? 'auto' : '0')};
      margin-top: ${prop => (prop.theme.isDesktop ? '0' : '0.32rem ')};
    }
    .planet-3 {
      width: ${prop => (prop.theme.isDesktop ? '380px' : 'calc(100% - 0.8rem)')};
      height: ${prop => (prop.theme.isDesktop ? '380px' : 'calc(100% - 0.8rem)')};
      margin-left: ${prop => (prop.theme.isDesktop ? 'auto' : '0')};
      margin-top: ${prop => (prop.theme.isDesktop ? '0' : '0.36rem ')};
    }
    .button-wrapper {
      margin: 0;
      width: fit-content;
    }
  }
  .reverse {
    flex-direction: column-reverse;
  }
  .feat {
    width: ${prop => (prop.theme.isDesktop ? 'auto' : '100%')};
    ${prop => !prop.theme.isDesktop && `
      padding: 0 0.4rem;
    `}
    .feat-title {
      color: ${prop => prop.theme.grey1};
      font-weight: 600;
      font-size: ${prop => (prop.theme.isDesktop ? '28px' : '0.56rem')};
      line-height: ${prop => (prop.theme.isDesktop ? '36px' : '0.32rem')};
      margin-top:  ${prop => (prop.theme.isDesktop ? '48px' : '0.4rem')};
    }
    .feat-desc {
      color: ${prop => prop.theme.grey1};
      font-weight: 400;
      font-size: ${prop => (prop.theme.isDesktop ? '16px' : '0.32rem')};
      line-height: ${prop => (prop.theme.isDesktop ? '28px' : '0.56rem')};
      margin-top: ${prop => (prop.theme.isDesktop ? '12px' : '0.3rem')};
      margin-bottom:  ${prop => (prop.theme.isDesktop ? '20px' : '0.2rem')};
    }
    .line {
      width: ${prop => (prop.theme.isDesktop ? '400px' : 'calc(100% - 0.8rem)')};
    }
    .line-reverse {
      transform: rotateY(180deg);
    }
    .feat-text {
      color: ${prop => prop.theme.grey3};
      font-weight: 400;
      font-size: ${prop => (prop.theme.isDesktop ? '14px' : '0.28rem')};
      line-height: ${prop => (prop.theme.isDesktop ? '22px' : '0.44rem')};
      margin-bottom:  ${prop => (prop.theme.isDesktop ? '20px' : '0.2rem')};
    }

  }
  .right {
    align-items: flex-end;
    text-align: end;
  }
  .end {
    padding-left: ${prop => (prop.theme.isDesktop ? '0' : '0.5rem')};
    padding-top: 6px;
  }
`

const Home: React.FC = () => {
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const { darkMode } = useContext(ThemeContext)

  return (
    <Wrapper>
      <div className="head">
        <img src={BG_HEAD} className="head-img"/>
      </div>
      <div className="title">Make Cross-Chain <br />as Easy as Transfer</div>
      <div className="button-wrapper">
      {/* <div className="defalut" onClick={() => handleEvent('home_btn_launchpad')}>Launchpad<div className="bg">coming soon</div></div> */}
      <a
        href="https://trantor.network/pdf/litepaper.pdf"
        target="_blank"
        rel="noreferrer"
        className="defalut" onClick={() => handleEvent('Btn_Litepaper')}>Litepaper</a>
      <Link className="button-primary" to="/bridge" onClick={() => handleEvent('Btn_Bridge')}>Bridge</Link>

      </div>
      <div className="se-title">About Trantor</div>
      <div className="desc">
        Trantor is an omnichain asset transport protocol that allows oassets to be transferred between chains with 100% liquidity and zero fee
      </div>
      <div className="se-desc">No fees, no limits, and fast. Just Tr(y)a it.</div>

      <div className="row">
        <div className="feat">
          <img className="line" src={darkMode ? LINE_DARK : LINE_LIGHT} />
          <div className="feat-title">Unlimited Liquidity</div>
          <div className="feat-desc">Trantor does not rely on LP pools, so there is no liquidity depletion <br/>
          and is always 100% liquid.
           </div>
          <div className="feat-text">Trantor is the next generation of cross-chain bridges, focused on enabling assets to cross-chain natively.</div>
          <a className="button-primary"
            onClick={() => handleEvent('Btn_LearnMore')}
            href="https://medium.com/@trantornetwork" target="_blank" rel="noreferrer" >Learn More</a>
        </div>
        <img className="planet-1" src={PLANET_1} />
      </div>
      <div className={isDesktop ? 'row' : 'row reverse'}>
        <img className="planet-2" src={PLANET_2} />
        <div className={isDesktop ? 'feat right' : 'feat'}>
          <img className={isDesktop ? 'line line-reverse' : 'line'} src={darkMode ? LINE_DARK : LINE_LIGHT} />
          <div className="feat-title">Totally Free</div>
          <div className="feat-desc">Transfer assets to different chains without fees.</div>
          <div className="feat-text">With Trantor, cross-chain is as easy as transfer.</div>
          <Link to="/bridge">
            <div className="button-primary" onClick={() => handleEvent('Btn_Bridge_2')}>Bridge</div>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="feat end">
         <img className="line" src={darkMode ? LINE_DARK : LINE_LIGHT} />
          <div className="feat-title">Cross-Chain Swap</div>
          <div className="feat-desc">With omnichain liquidity, cross-chain swap is becoming more and more accessible.</div>
          <div className="feat-text">Accelerated development in progress.</div>
          <div className="button-wrapper">
            <div className="defalut">Swap<div className="bg">coming soon</div></div>
          </div>
        </div>
        <img className="planet-3" src={PLANET_3} />
      </div>
      <div className={isDesktop ? 'row' : 'row reverse'}>
        <img className="planet-2" src={PLANET_4} />
        <div className={isDesktop ? 'feat right' : 'feat'}>
          <img className={isDesktop ? 'line line-reverse' : 'line'} src={darkMode ? LINE_DARK : LINE_LIGHT} />
          <div className="feat-title">Omnichain NFT Market</div>
          <div className="feat-desc">Purchase NFTs from all chains in one.</div>
          <div className="feat-text">Users can buy NFTs across all chains on one platform and<br /> NFT sellers can find more potential buyers across all chains.</div>
          <Link
            to="/market"
            className="button-primary"
            onClick={() => handleEvent('Btn_GoToMarket')}
            >Go to Market</Link>
        </div>
      </div>
    </Wrapper>
  )
}

export default Home
