export default {
  NFT_ON_BSC: '0xfbe6bC8Bb781c509ac208B2db59a915Ce748B608',

  Bridge_ON_BSC: '0x196b971F13165351aeA89e1C84DE6597354f3deA',
  TEST_NFT_BSC: '0x4c3e87Add69CA7FE21D51B2507449de171725048',

  NFT_ON_OP: '0xC6841E759130f9Cf2E05DfA9208a834aff0d6d9D',
  Bridge_ON_OP: '0x62e933E29A874f6A5796434E5950D02925bD9C11',

  NFT_ON_Rinkeby: '0x3Cb2DFECD6C508680553BF49FD879DA809810F2C',
  Bridge_ON_Rinkeby: '0x6c274C23D0E3A25F3b28deB6b6e0EF8cc850E6eB',
  TEST_NFT_Rinkeby: '0x1A18b70DF88183F32133049bEa37dE282C8F70f8',

  NFT_ON_Mumbai: '0x4614ea9339D498B8230081e9B0589D0Da4595394',
  Bridge_ON_Mumbai: '0x98dBdAC408484290E4eDFbe2E94b5a3122eFFA0d',
  TEST_NFT_Mumbai: '0xD7A2529c489cA3B36ff6aF3193Bc38D9BDe76519',

  NFT_ON_Arbitrum: '0x82deA80212bE41639792EF76d0538FE6d5cB2343',
  Bridge_ON_Arbitrum: '0xFf427B2CF6A7F50092B2D1BBC70f217336ed05eC',

  REACT_APP_NETWORK_URL: 'https://mainnet.infura.io/v3/099fc58e0de9451d80b18d7c74caa7c1',

  Ggraph_url: 'https://api.thegraph.com/subgraphs/name/damocles0x',
}
