import { createGlobalStyle } from 'styled-components'
import '@mui/styles'
import { createTheme, Theme } from '@mui/material'
import { isDesktop } from './redux/reducer'
import GS_M from './fonts/Gill-Sans-Medium.ttf'
import GS_B from './fonts/Gill-Sans-Bold.ttf'
import GS_R from './fonts/Gill-Sans-Regular.ttf'
import SF_M from './fonts/SF-UI-Text-Medium.otf'
import SF_R from './fonts/SF-UI-Text-Regular.otf'

interface CustomTheme {
  grey1: string
  grey2: string
  grey3: string
  grey4: string
  grey5: string
  grey6: string
  hoverBc: string
  fontLargest: string
  fontLarge: string
  fontNormal: string
  fontSmall: string
  primaryColor: string
  borderColor: string
  isDesktop: boolean
  darkMode?: boolean
  bgUrl: string
}
declare module '@mui/styles/defaultTheme' {
  type DefaultTheme = Theme
}

let defaultTheme: CustomTheme = {
  grey1: '#000000',
  grey2: '#666666',
  grey3: '#333333',
  grey4: '#999999',
  grey5: '#FF3838',
  grey6: '#3D8DE2',
  hoverBc: '#EBEBEB',
  fontLargest: '24px',
  fontLarge: '20px',
  fontNormal: '16px',
  fontSmall: '14px',
  primaryColor: '#0057ff;',
  borderColor: '#3333333',
  isDesktop,
  darkMode: false,
  bgUrl: '',
}

if (!isDesktop) {
  defaultTheme = {
    ...defaultTheme,
    ...{
      fontLargest: '0.48rem',
      fontLarge: '0.40rem',
      fontNormal: '0.32rem',
      fontSmall: '0.28rem',
    },
  }
}

const lightTheme = createTheme(
  {},
  {
    ...defaultTheme,
  },
)

const darkTheme = createTheme(
  {},
  {
    ...defaultTheme,
    ...{
      grey1: '#FFFFFF',
      grey2: '#DCDCDC',
      grey3: '#999999',
      grey4: '#666666',
      grey5: '#FF3838',
      grey6: '#3D8DE2',
      hoverBc: '#252525',
      darkMode: true,
    },
  },
)

const flexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`
export const primaryLinear = `linear-gradient(89.81deg, #FFA8A8 -6.4%, #FCFF00 109.94%);`

export const flexRow = `
  display: flex;
  flex-direction:row ;
`
export const flexColumn = `
  display: flex;
  flex-direction: column;
`

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'GSMedium';
    src: url(${GS_M});
  }
  @font-face {
    font-family: 'GSBold';
    src: url(${GS_B});
  }
  @font-face {
    font-family: 'GSRegular';
    src: url(${GS_R});
  }
  @font-face {
    font-family: 'SFRegular';
    src: url(${SF_R});
  }
  @font-face {
    font-family: 'SFMedium';
    src: url(${SF_M});
  }
  html {
    height: 100%;
    font-size: 16px;
  }
  #root{
      position: relative;
      min-height: 100vh;
  }

  body {
    position: relative;
    ${prop =>
      // @ts-ignore
      prop.theme.isDesktop && `min-height: 900px;`}
    height: 100%;
    position: relative;
    // font-family: GSRegular;

    // fix style: WalletConnect modal
    #walletconnect-qrcode-modal {
      .walletconnect-modal__base {
        top: 24%;
        margin: ${prop =>
          // @ts-ignore
          prop.theme.isDesktop ? 'auto' : 'auto 0'};
      }
      .walletconnect-modal__mobile__toggle {
        display: ${prop =>
          // @ts-ignore
          prop.theme.isDesktop ? 'none' : 'flex'};
        flex-direction: row;
      }
    }
  }

  body, textarea, input, button {
    line-height: 1;
  }

  body, div, p {
    margin: 0;
    padding: 0;
  }

  button, a {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    cursor: ${prop =>
      // @ts-ignore
      prop.theme.isDesktop ? 'pointer' : 'none'};
    &:focus {
      outline: 1px solid rgba(0, 0, 0, .1);
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    :focus {
      outline: none;
    }
  }

  img {
    display: block;
  }

  strong {
    font-weight: bold;
  }

  div, a, button, li {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  // fix style: coinbase modal
  .-cbwsdk-css-reset {
    .-cbwsdk-extension-dialog-box-top-install-region {
      button {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }
    .-cbwsdk-snackbar-instance {
      width: 100%;
    }
  }
`

export { darkTheme, lightTheme, defaultTheme, GlobalStyle, flexCenter }
