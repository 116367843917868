import styled from 'styled-components'

const NormalButton = styled.div`
  height: 48px;
  text-align: center;
  background: ${prop => (prop.theme.darkMode ? '#222222' : '#FFFFFF;')};
  border-radius: 10px;
  border: 1px solid ${prop => prop.theme.grey3};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontNormal};
  color: ${prop => prop.theme.grey3};
  cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
  :hover {
    text-decoration: underline;
  }
`

export default NormalButton
