import styled from 'styled-components'
import { flexCenter } from '../../style'

const NavigationButton = styled.div<{ isActive?: boolean} >`
  ${flexCenter};
  height: 40px;
  margin-left: 40px;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontNormal};
  line-height: 16px;
  color: #666666;
  ${prop => prop.isActive && `
    color: ${prop.theme.darkMode ? '#fff' : '#000'};
  `}

`

export default NavigationButton
