import styled from 'styled-components'
import {ConnectButton} from '@suiet/wallet-kit'
import { defaultTheme, flexCenter, flexRow } from '../../style'

const WalletWrapper = styled.div`
  ${flexCenter};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: ${prop => prop.theme.grey3};
  font-size: ${defaultTheme.fontSmall};
  cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
  background-color: #fff;
  ${prop => prop.theme.darkMode && `
    background-color: #000;
    border: 1px solid #666;
  `};
  .avatar {
    ${flexCenter};
    margin-right: 16px;
    .wallet-icon {
      width: 28px;
      height: auto;
      margin: 0;
      ${prop => !prop.theme.isDesktop && `
      width: 16px;
      height: 16px;
    `}
    }
  }
  :hover {
    opacity: 0.9;
    ${prop => prop.theme.darkMode && `
    background-color: rgba(255,255,255,0.1);
    border: 1px solid #666;
    opacity: 1;
  `};
  }
`

const WalletModal = styled.div`
  padding: 30px 24px 40px 24px;
  width: ${prop => (prop.theme.isDesktop ? '400px' : '7.02rem')};
  height: ${prop => (prop.theme.isDesktop ? 'auto' : '12rem')};
  .label {
    ${flexCenter};
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    padding: 18px 24px;
    cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
    font-weight: 500;
    border: 1px solid ${prop => prop.theme.darkMode ? '#666666' : '#D9D9D9'};
    .icon {
      width: 24px;
      height: 24px;
    }
    :hover {
      border-color: #999999;
    }
  }
  .content {
    font-size: ${defaultTheme.fontNormal};
    padding: 20px 0;
    text-align: center;
  }

  .walletSeletor {
    font-size: ${prop => prop.theme.fontNormal};
    text-align: center;
    border: 1px solid ${prop => (prop.theme.darkMode ? '#353743' : '#CDCDCD')};
    background-color: ${prop => (prop.theme.darkMode ? '#19191B' : '#F4F5F6;')};
    border-radius: 8px;
    margin-bottom: 8px;
    color: ${props => props.theme.grey1};
  }

  .worong-network {
    margin-top: 133px;
    .img {
      width: 105px;
      height: 105px;
      margin: 0 auto;
    }
    .content {
      margin-bottom: 152px;
      font-weight: 400;
      font-size: ${prop => prop.theme.fontLarge};
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.14px;
      color: ${prop => prop.theme.grey3};
    }
  }
  ${prop => prop.theme.isDesktop && H5WalletModal}
`
const H5WalletModal = styled.div`
  padding: 0 24px 40px 24px;
  width: 320px;
  .walletSeletor {
    font-size: ${defaultTheme.fontNormal};
    text-align: center;
    background: #f4f5f6;
    border-radius: 6px;
  }
  .content {
    font-size: ${defaultTheme.fontNormal};
    padding: 20px 0;
    text-align: center;
  }
  .label {
    ${flexCenter};
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    padding: 16px 24px;
    margin-top: 8px;
    cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
    font-weight: 500;
    .icon {
      width: 24px;
      height: 24px;
    }
  }

  .img {
    width: 105px;
    height: 105px;
    margin: 0 auto;
  }
`

const H5Selector = styled.div`
  ${flexRow}
  align-items: center;
  img {
    height: 0.36rem;
    width: 0.36rem;
  }
  .account {
    ${flexRow}
    align-items: center;
    justify-content: space-around;
    height: 0.46rem;
    width: 2.35rem;
    margin-left: 5px;
    border-radius: 0.32rem;
    background: ${prop => (prop.theme.darkMode ? '#333333' : '#ffffff')};
    font-weight: 400;
    font-size: 0.24rem;
    color: ${props => props.theme.grey1};
    img {
      width: 12px;
      height: 12px;
    }
  }
`

const AccountModal = styled.div`
  padding: 0 24px 40px 24px;
  width: 398px;
  .content {
    position: relative;
    .account-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 76px;
      width: 357px;
      border-radius: 8px;
      padding: 18px 9px;
      background-color: ${prop => (prop.theme.darkMode ? '#19191B' : '#F4F5F6;')};
      color: #101010;
      line-height: 23px;
      margin-bottom: 12px;
      .avatar {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
      .account {
        text-decoration: underline;
        color: ${prop => prop.theme.grey1};
        font-size: ${prop => prop.theme.fontNormal};
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      color: #101010;
      line-height: 23px;
      margin-bottom: 12px;
      .avatar {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
      .copy-icon {
        width: 24px;
        height: 24px;
        margin-left: 12px;
        cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
      }
    }
    .desc {
      color: #999999;
      line-height: 15px;
      font-size: 12px;
      margin-bottom: 20px;
    }
    .change-button {
      width: 84px;
      height: 36px;
      line-height: 36px;
      cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
    }
  }
  .disConnect-button {
    ${flexRow}
    align-items: center;
    justify-content: center;
    width: 355px;
    height: 53px;
    padding: 14px 0;
    background-color: ${prop => (prop.theme.darkMode ? '#19191B' : '#F4F5F6;')};
    cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
    border-radius: 82px;
    text-align: center;
    font-weight: 400;
    font-size: ${prop => prop.theme.fontNormal};
    line-height: 19px;
    color: ${prop => prop.theme.grey1};
    img {
      width: 23.65px;
      height: 23.65px;
      margin-right: 22.16px;
    }
  }
  .label {
    ${flexCenter};
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    padding: 16px 0;
    margin-top: 8px;
    cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
    font-weight: 500;
    background: #f4f5f6;
  }
`

const AccountDrawer = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 0.4rem;
    color: #333333;
    margin-bottom: 0.65rem;
  }
  .content {
    position: relative;
    .account-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 6.9rem;
      height: 1.46rem;
      border-radius: 0.16rem;
      background-color: #f4f5f6;
      line-height: 23px;
      margin-bottom: 12px;
      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        .avatar {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }
        .account {
          text-decoration: underline #0057ff;
          color: #0057ff;
          font-size: ${prop => prop.theme.fontNormal};
        }
      }
    }
  }
  .disConnect-button {
    ${flexRow}
    align-items: center;
    justify-content: center;
    width: 6.9rem;
    height: 0.94rem;
    padding: 14px 0;
    background-color: #f4f5f6;
    cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
    border-radius: 82px;
    text-align: center;
    font-weight: 400;
    font-size: ${prop => prop.theme.fontNormal};
    line-height: 19px;
    color: #999999;
    img {
      width: 23.65px;
      height: 23.65px;
      margin-right: 22.16px;
    }
  }
`

const H5BottomWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 32px 32px 0px 0px;
  height: 60px;
  padding: 0 20px;
`

export const ConnectButtonWrapper = styled.div`
  .wkit-button {
    ${flexCenter};
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 16px;
    padding: 0 20px;
    color: ${prop => prop.theme.grey3};
    font-size: ${defaultTheme.fontSmall};
    cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
    background-color: #fff;
    ${prop => prop.theme.darkMode && `
      background-color: #000;
      border: 1px solid #666;
  `};
    :hover {
      background-color: #fff;
      opacity: 0.9;
      ${prop => prop.theme.darkMode && `
      background-color: rgba(255,255,255,0.1);
      border: 1px solid #666;
      opacity: 1;
    `};
    }
  }
  .wkit-connected-container {
    width: fit-content;
    background-color: #fff;
    border: 1px solid #666;
    padding: 0 10px;
    border-radius: 12px;
    ${prop => prop.theme.darkMode && `
    background-color: #000;
    border: 1px solid #666;
`};
  }
  .wkit-connected-button__balance,
  .wkit-address-select__right-arrow, 
  .wkit-connected-button__divider {
    display: none;
  }
  .wkit-connected-button {
    color: ${prop => prop.theme.grey3} !important;
  }
  .wkit-address-select {
    color: ${prop => prop.theme.grey3}!important;
  }

` 

export { WalletWrapper, WalletModal, AccountModal, AccountDrawer, H5BottomWrapper, H5Selector }
