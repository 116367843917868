import OpIcon from './images/optimism-logo.svg'
import BscIcon from './images/bsc-logo.png'
import ETHIcon from './images/ethereum-logo.png'
import RinkebyIcon from './images/rinkeby-icon.png'
import MumbaoIcon from './images/mumbai-icon.png'
import SuiIcon from './images/sui.png'
import { Connector } from '@web3-react/types'
import { coinbaseWallet, injected, network, walletConnect } from './connectors'
// import VConsole from 'vconsole'

// const vConsole = new VConsole()

export enum ChainId {
  MAINNET = 1,
  BSC_TEST = 97,
  OPTIMISM = 69,
  MUMBAI = 80001,
}

export const CHAIN_IDS_TO_NAMES = {
  [ChainId.MAINNET]: 'mainnet',
  [ChainId.BSC_TEST]: 'BSC_Testnet',
  [ChainId.OPTIMISM]: 'optimism',
  [ChainId.MUMBAI]: 'mumbai_Testnet',
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: ChainId[] = Object.values(ChainId).filter(
  id => typeof id === 'number',
) as ChainId[]

// 默认显示的链
export const DEFAULT_NETWORK = ChainId.BSC_TEST

export const SUPPORTED_CHAIN_IDS = [
  ChainId.MAINNET,
  ChainId.BSC_TEST,
  ChainId.MUMBAI,
]

interface NetworkConfig {
  [key: number]: {
    chainId: typeof SUPPORTED_CHAIN_IDS
    chainName: string
    rpcUrls: string[]
    logo: string
    explorer: string
    nativeCurrency: {
      name: string
      symbol: string
      decimals: number
    }
  }
}
export const NETWORK_CONFIG: NetworkConfig = {
  [ChainId.MAINNET]: {
    chainId: [ChainId.MAINNET],
    chainName: 'Ethereum',
    rpcUrls: ['https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7'],
    logo: ETHIcon,
    explorer: 'https://etherscan.io/',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [ChainId.BSC_TEST]: {
    chainId: [ChainId.BSC_TEST],
    chainName: 'BSC Testnet',
    rpcUrls: ['https://bsc-testnet.public.blastapi.io'],
    logo: BscIcon,
    explorer: 'https://testnet.bscscan.com/',
    nativeCurrency: {
      name: 'tBNB',
      symbol: 'BNB',
      decimals: 18,
    },
  },
  [ChainId.OPTIMISM]: {
    chainId: [ChainId.OPTIMISM],
    chainName: 'Optimism Kovan',
    rpcUrls: ['https://kovan.optimism.io/'],
    logo: OpIcon,
    explorer: 'https://kovan-optimistic.etherscan.io/',
    nativeCurrency: {
      name: 'KOR',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [ChainId.MUMBAI]: {
    chainId: [ChainId.MUMBAI],
    chainName: 'Mumbai Testnet',
    rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
    logo: MumbaoIcon,
    explorer: 'https://mumbai.polygonscan.com/',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
}

export const isChainAllowed = (connector: Connector, chainId?: number) => {
  if (!chainId) return false
  switch (connector) {
    case injected:
    case coinbaseWallet:
    case walletConnect:
    case network:
      return SUPPORTED_CHAIN_IDS.includes(chainId)
    default:
      return false
  }
}

export const switchChain = async (connector: Connector, chainId: number) => {
  if (!isChainAllowed(connector, chainId)) {
    throw new Error(`Chain ${chainId} not supported for connector (${typeof connector})`)
  } else if (connector === walletConnect || connector === network) {
    await connector.activate(chainId)
  } else {
    const info = NETWORK_CONFIG[chainId]
    const addChainParameter = {
      chainId,
      chainName: info.chainName,
      rpcUrls: info.rpcUrls,
      nativeCurrency: info.nativeCurrency,
      blockExplorerUrls: [info.explorer],
    }
    await connector.activate(addChainParameter)
  }
}
