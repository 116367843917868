import { createContext, useEffect, useState, useContext, useMemo } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useLocation, matchRoutes } from 'react-router-dom'
import routes from '../routerConfig'
import { AppDispatch, AppState } from '../redux/store'
import { updateSlidevisiable, updateSlideTitle } from '../redux/reducer'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'

interface Theme {
  darkMode: boolean
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>
}
export const ThemeContext = createContext({} as Theme)

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export const usePath = () => {
  const { pathname } = useLocation()
  const [isHome, setIsHome] = useState(true)
  const [isMarket, setIsMarket] = useState(false)

  useEffect(() => {
    setIsHome(pathname === '/')
    setIsMarket(pathname === '/market')
  }, [pathname])
  return { isHome, isMarket}
}

export const useSlideVisiable = () => {
  const dispatch = useAppDispatch()
  const slidevisiable = useAppSelector(state => state.app.slidevisiable)
  const dispatchSlideStatus = (value: boolean) => {
    dispatch(updateSlidevisiable({ slidevisiable: value }))
  }
  return { slidevisiable, dispatchSlideStatus }
}

export const useSlideTitle = () => {
  const dispatch = useAppDispatch()
  const slideTitle = useAppSelector(state => state.app.slideTitle)
  const dispatchSlideTitle = (value: string) => {
    dispatch(updateSlideTitle({ slideTitle: value }))
  }
  return { slideTitle, dispatchSlideTitle }
}

export const useCurrentPath = () => {
  const location = useLocation()
  const [{ route }] = matchRoutes(routes, location)!
  return route.path || ''
}

export const useThemeContext = () => {
  const context = useContext(ThemeContext)
  return context
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}
// account is not optional
function getSigner(provider: JsonRpcProvider, account: string): JsonRpcSigner {
  return provider.getSigner(account).connectUnchecked()
}
// account is optional
function getProviderOrSigner(provider: JsonRpcProvider, account?: string): JsonRpcProvider | JsonRpcSigner {
  return account ? getSigner(provider, account) : provider
}

// account is optional
export function getContract(address: string, ABI: any, provider: JsonRpcProvider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(provider, account) as any)
}

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T
}

