// 获取短地址
import toast from '../components/Toast/Toast'
import { ethers } from 'ethers'

const shortenAddress = (address: string, front = 6, behind = 4): string => {
  return `${address.substring(0, front)}...${address.substring(address.length - behind)}`
}

const chunk = (arr: any[], num: number) => {
  const spliceArr = arr.slice(0)
  return Array.from({ length: Math.ceil(arr.length / num) }, () => {
    return spliceArr.splice(0, num)
  })
}

const decimalToHex = (decimal: number) => {
  return `0x${decimal.toString(16)}`
}

export const walletTips = () => {
  toast({ text: 'Please connect the wallet', type: 'warning' })
}

export const DESKTOP_WIDTH = 768
export let isDesktop = false
if (typeof document !== 'undefined') {
  if (window.innerWidth >= DESKTOP_WIDTH) {
    isDesktop = true
  }
}

const ios = /iPad|iPhone|iPod/.test(navigator.userAgent)

export function formatTime(dataStr: string, divider: string) {
  try {
    const date = new Date(dataStr)
    const arr = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
    return arr.join(divider)
  } catch (e) {
    console.log('---time is invalid')
  }
}

export const getBalace = (value: string, noDevideEighth?: boolean) => {
  if (noDevideEighth) {
    return parseFloat(ethers.utils.formatUnits(value, 0))
  }
  return parseFloat(ethers.utils.formatEther(value))
}

export function BalanceToValue(
  value: string,
  decimalNum?: number,
  // noDevideEighth?: boolean,
) {
  // tranfer bignumer type
  if (!decimalNum) {
    // eslint-disable-next-line no-param-reassign
    decimalNum = 4
  }
  const num = Number(value) / 10 ** 18
  // eslint-disable-next-line no-mixed-operators
  const result = Math.floor(num * 10 ** decimalNum) / 10 ** decimalNum
  const resArr = String(result).split('.')
  if (resArr[1]) {
    if (resArr[1].length !== decimalNum) {
      const decimal = resArr[1].padEnd(decimalNum, '0')
      return `${resArr[0]}.${decimal}`
    }
    return result.toString()
  } else {
    // 没有小数点后的处理(整数)
    return `${resArr[0]}.${''.padEnd(decimalNum, '0')}`
  }
}
const jsonToQuery = (json: any) => {
  return Object.keys(json)
    .map((key: any) => {
      return `${key}=${json[key]}`
    })
    .join('&')
}
export { shortenAddress, chunk, decimalToHex, ios, jsonToQuery }
