import { AccountDrawer, AccountModal, ConnectButtonWrapper, H5Selector, WalletModal, WalletWrapper } from './WalletStyle'
// import WalletIcon from './images/wallet-icon.svg'
import DISCONNECTICON from './images/disConnect.png'
import React, { useCallback, useEffect, useState } from 'react'
import { useThemeContext, useAppDispatch, useAppSelector, usePath } from '../../helpers/hooks'
import Modal from '../Modal'
import {
  DEFAULT_NETWORK,
  isChainAllowed,
  NETWORK_CONFIG,
  SUPPORTED_CHAIN_IDS,
  switchChain,
} from '../../web3/chain'
import toast from '../Toast/Toast'
import { shortenAddress } from '../../helpers/utils'
import { useClipboard } from 'use-clipboard-copy'
import NetworkSelector from './NetworkSelector'
import WalletWarningIcon from './images/wallet-warning.png'
import WalletWarningIconDark from './images/wallet-warning-dark.png'
import CopyIcon from './images/copy.svg'
import Button from '../Button'
import NormalButton from '../NormalButton'
import { useLoading } from '../Loading/Loading'
import Drawer from '../Drawer'
import Identicon from './Identicon'
import { useWeb3React } from '@web3-react/core'
import { coinbaseWallet, getWalletForConnector, injected } from '../../web3/connectors'
import { Connector } from '@web3-react/types'
import { updateSelectedWallet } from '../../redux/reducer'
import { DEFAULT_WALLET, isBitkeep, isOKXWallet, SUPPORTED_WALLETS } from '../../web3/wallet'
import ACCOUNT_BG from './images/account-bg.png'
import BG from '../SlidePage/images/bg.png'
import SelectIcon from './images/selectIcon.png'
import {ConnectButton} from '@suiet/wallet-kit'

const EXPIRED_TIMESTAMP = 1000 * 3600 * 24

const Wallet: React.FC = () => {
  const [showDisconnectModal, setShowDisconnectModal] = useState(false)
  const { account, chainId, connector, isActive } = useWeb3React()
  const clipboard = useClipboard()
  const [switchChainModal, setSwitchChainModal] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)
  const loading = useLoading()
  const { darkMode } = useThemeContext()
  const { isHome, isMarket } = usePath()
  const dispatch = useAppDispatch()
  const isDesktop = useAppSelector(state => state.app.isDesktop)
  const selectedWallet = useAppSelector(state => state.app.selectedWallet)
  const lastTimestamp = useAppSelector(state => state.app.lastTimestamp)

  const handleConnect = useCallback(
    async (currentConnector: Connector) => {
      const wallet = getWalletForConnector(currentConnector)
      try {
        await currentConnector.activate()
        setShowDisconnectModal(false)
        dispatch(updateSelectedWallet({ wallet }))
      } catch (error) {
        console.debug(`web3-react connection error: ${error}`)
        // toast({ text: error.message, type: 'error' })
      }
    },
    [dispatch],
  )

  useEffect(() => {
    // Expired
    const isExpired = (lastTimestamp + EXPIRED_TIMESTAMP) < new Date().getTime()
    if (!account && selectedWallet && !isExpired) {
      handleConnect(connector)
    }
  }, [account, selectedWallet, connector, lastTimestamp])

  // 断开钱包链接
  const handleDisconnect = () => {
    if (connector.deactivate) {
      connector.deactivate()
      // Coinbase Wallet SDK does not emit a disconnect event to the provider,
      // which is what web3-react uses to reset state. As a workaround we manually
      // reset state.
      if (connector === coinbaseWallet) {
        connector.resetState()
      }
    } else {
      connector.resetState()
    }
    setShowAccountModal(false)
    dispatch(updateSelectedWallet({ wallet: undefined }))
  }

  const openExplorer = () => {
    const url = `${NETWORK_CONFIG[chainId!].explorer}${`address`}/${account}`
    window.open(url)
  }

  const handleCopy = (value: string) => {
    if (clipboard.isSupported()) {
      clipboard.copy(value)
      setShowAccountModal(false)
      toast({ text: 'copy success' })
    }
  }

  useEffect(() => {
    if (account && chainId && !SUPPORTED_CHAIN_IDS.includes(chainId)) {
      setSwitchChainModal(true)
    }
  }, [chainId, account])

  const handleShowDisconnectModal = () => {
    if (chainId && !SUPPORTED_CHAIN_IDS.includes(chainId)) {
      setSwitchChainModal(true)
      return
    }
    setShowDisconnectModal(true)
  }

  const handleConnectNetwork = () => {
    loading.show()
    switchChain(DEFAULT_WALLET.connector!, DEFAULT_NETWORK)
      .then(() => {
        setSwitchChainModal(false)
      })
      .catch(() => {
        toast({ text: 'Something Wrong.Please try again', type: 'error' })
      })
      .finally(() => loading.hide())
  }

  const getOptions = () => {
    const isMetaMask = !!window.ethereum?.isMetaMask
    const isCoinbaseWallet = !!window.ethereum?.isCoinbaseWallet
    return Object.keys(SUPPORTED_WALLETS).map(key => {
      const option = SUPPORTED_WALLETS[key]

      if (!isDesktop) {
        if (
          (!window.web3 && !window.ethereum && option.mobile) ||
          (isMetaMask && option.name === 'MetaMask') ||
          (isCoinbaseWallet && option.name === 'Coinbase Wallet')
        ) {
          return (
            <div className="walletSeletor" key={key}>
              <div
                className={connector === option.connector && isActive ? 'label current' : 'label'}
                onClick={() => {
                  if (!option.href && !!option.connector) {
                    handleConnect(option.connector)
                  }
                }}
              >
                {option.name}
                <img className="icon" src={option.iconName} />
              </div>
            </div>
          )
        }
        return null
      }
      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <a
                className="walletSeletor"
                key={key}
                href="https://metamask.io/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="label">
                  Install MetaMask
                  <img className="icon" src={option.iconName} />
                </div>
              </a>
            )
          }  else if (option.name === SUPPORTED_WALLETS.OKX.name) {
            return (
              <a className="walletSeletor" key={key} href="https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge" target="_blank" rel="noreferrer">
                 <div className="label">
                  Install okx
                  <img className="icon" src={option.iconName} />
                </div>
              </a>
            )
          }
          else if (option.name === SUPPORTED_WALLETS.BITKEEP.name) {
            return (
              <a className="walletSeletor" key={key} href="https://chrome.google.com/webstore/detail/bitkeep-crypto-nft-wallet/jiidiaalihmmhddjgbnbgdfflelocpak" target="_blank" rel="noreferrer">
                 <div className="label">
                  Install BitKeep
                  <img className="icon" src={option.iconName} />
                </div>
              </a>
            )
          }else {
            return null // dont want to return install twice
          }
        }
        else if (!isOKXWallet && option.name === SUPPORTED_WALLETS.OKX.name) {
          return (
            <a className="walletSeletor" key={key} href="https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge" target="_blank" rel="noreferrer">
               <div className="label">
                Install okx
                <img className="icon" src={option.iconName} />
              </div>
            </a>
          )
        }
        else if (!isBitkeep && option.name === SUPPORTED_WALLETS.BITKEEP.name) {
          return (
            <a className="walletSeletor" key={key} href="https://chrome.google.com/webstore/detail/bitkeep-crypto-nft-wallet/jiidiaalihmmhddjgbnbgdfflelocpak" target="_blank" rel="noreferrer">
               <div className="label">
                Install BitKeep
                <img className="icon" src={option.iconName} />
              </div>
            </a>
          )
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetaMask) {
          return null
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetaMask) {
          return null
        }
      }
      return (
        <div className="walletSeletor" key={key}>
          <div
            className={connector === option.connector && isActive ? 'label current' : 'label'}
            onClick={() => handleConnect(option.connector!)}
          >
            {option.name}
            <img className="icon" src={option.iconName} />
          </div>
        </div>
      )
    })
  }
  // 未连接弹窗
  const DisconnectModal = () => {
    return (
      <Modal
        open={showDisconnectModal}
        title="Connect Wallet"
        onClose={() => setShowDisconnectModal(false)}
        bgUrl={BG}
      >
        <WalletModal>
          {getOptions()}
        </WalletModal>
      </Modal>
    )
  }

  const formatConnectorIcon = () => {
    const icon = Object.keys(SUPPORTED_WALLETS).filter(k => SUPPORTED_WALLETS[k].connector === connector).map(k => SUPPORTED_WALLETS[k].iconName)[0]
    if (icon === SUPPORTED_WALLETS.METAMASK.iconName && isOKXWallet) return SUPPORTED_WALLETS.OKX.iconName
    if (icon === SUPPORTED_WALLETS.METAMASK.iconName && isBitkeep) return SUPPORTED_WALLETS.BITKEEP.iconName
    return icon
  }
  const walletWrapper = () => {
    if (!account) {
      return (
        <>
          {isDesktop && (
            <WalletWrapper onClick={handleShowDisconnectModal}>
              Connect Wallet
            </WalletWrapper>
          )}
        </>
      )
    }
    return (
      <WalletWrapper onClick={() => setShowAccountModal(true)}>
        <div className="avatar">
          {connector && <img className='wallet-icon' src={formatConnectorIcon()} />}
        </div>
        {shortenAddress(account!)}
      </WalletWrapper>
    )
  }
  const suiWalletWrapper = () => {
    return <ConnectButtonWrapper>
      <ConnectButton label='Connect Sui Wallet' />
    </ConnectButtonWrapper>
  }
  return (
    <>
      {(!isHome && !isMarket) && isDesktop && suiWalletWrapper()}
      {(!isHome && !isMarket) && (
        <NetworkSelector
          handleNoWallet={!isActive && !account ? handleShowDisconnectModal : undefined}
        />
      )}
      {!isDesktop && !isHome && !isMarket && (
        <H5Selector
          onClick={account ? () => setShowAccountModal(true) : () => handleConnectNetwork()}
        >
          <img
            src={
              NETWORK_CONFIG[isChainAllowed(connector, chainId) ? chainId! : DEFAULT_NETWORK].logo
            }
          />
          <div className="account">
            {account ? (
              <>
                {shortenAddress(account || '', 5, 5)}
                <img src={SelectIcon} alt="" />
              </>
            ) : (
              'connect wallet'
            )}
          </div>
        </H5Selector>
      )}

      {isDesktop && walletWrapper()}
      <Modal
        leftContent={<div>Account</div>}
        open={showAccountModal && isDesktop}
        onClose={() => setShowAccountModal(false)}
        bgUrl={ACCOUNT_BG}
      >
        <AccountModal>
          <div className="content">
            <div className="account-info">
              <div className="avatar">
                {
                  connector && <img className='wallet-icon' src={formatConnectorIcon()} />
                }
              </div>
                <a className="account" onClick={openExplorer}>
                {account && shortenAddress(account)}
              </a>
              <img src={CopyIcon} className="copy-icon" onClick={() => handleCopy(account!)} />
              <NormalButton className="change-button" onClick={() => setShowDisconnectModal(true)}>
                Change
              </NormalButton>
            </div>
          </div>
          <div className="disConnect-button" onClick={handleDisconnect}>
            <img src={DISCONNECTICON} alt="" />
            Disconnect
          </div>
        </AccountModal>
      </Modal>
      <DisconnectModal />
      <Modal
        title="Wrong Network"
        open={switchChainModal}
        onClose={() => setSwitchChainModal(false)}
        bgUrl={BG}
      >
        <WalletModal>
          <div className="worong-network">
            <img className="img" src={darkMode ? WalletWarningIconDark : WalletWarningIcon} />
            <div className="content">
              <p>Please switch to</p>
              <p>a currently supported network</p>
            </div>
            <Button fullWidth onClick={handleConnectNetwork} text="Switch Network" />
          </div>
        </WalletModal>
      </Modal>
      <Drawer
        open={showAccountModal && !isDesktop}
        onClose={() => setShowAccountModal(false)}
        showCloseIcon={false}
      >
        <AccountDrawer>
          <div className="content">
            <div className="title"> Account</div>
            <div className="account-info">
              <div className="left">
                <div className="avatar">
                  {connector && <img className='wallet-icon' src={formatConnectorIcon()} />}
                </div>
                <div className="account" onClick={openExplorer}>
                  {account && shortenAddress(account, 15, 5)}
                </div>
              </div>

              <img src={CopyIcon} className="copy-icon" onClick={() => handleCopy(account!)} />
            </div>
          </div>
        </AccountDrawer>
      </Drawer>
    </>
  )
}

export default Wallet
