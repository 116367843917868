import { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../../helpers/hooks'

import { flexRow } from '../../style'


import DISCORD from './images/Discord.png'
import DISCORD_DARK from './images/Discord_dark.png'

import TWITTER_ICON from './images/Twitter.png'
import TWITTER_DARK from './images/Twitter_dark.png'

import MEDIUM from './images/Medium.png'
import MEDIUM_DARK from './images/Medium_dark.png'

import DOCS from './images/Docs.png'
import DOCS_DARK from './images/Docs-dark.png'
import { handleEvent } from '../../Reporter'

const Wrapper = styled.div`
  ${flexRow}
  justify-content: center;
  font-size: ${prop => prop.theme.isDesktop ? '14px' : '0.28rem'};
  color: ${prop => prop.theme.darkMode ? '#fff' : '#666666'};
  ${prop => !prop.theme.isDesktop && `
    width:100%;
  `}
  .item {
    ${flexRow};
    font-family: SFRegular;
    line-height: 16px;
    cursor: ${prop => prop.theme.isDesktop ? 'pointer' : 'none'};
    ${prop => !prop.theme.isDesktop && `
      width: 33.33%;
    `}
    .text {
      // margin-top: 3px;
    }
  }
  img {
    width: 16px;
    height: 16px;
    margin-left: 26px;
    margin-right: 8px;
    ${prop => !prop.theme.isDesktop && `
    margin-left: 0.52rem;
  `}
  }

`

const imgArray = [
  {
    darkICon: DOCS_DARK,
    icon: DOCS,
    url: 'https://docs.trantor.network/',
    text: 'Docs',
    desc: 'docs',
  },
  {
    darkICon: TWITTER_DARK,
    icon: TWITTER_ICON,
    url: 'https://twitter.com/TrantorNetwork',
    text: 'Twitter',
    desc: 'twitter',
  },
  {
    darkICon: MEDIUM_DARK,
    icon: MEDIUM,
    url: 'https://medium.com/@trantornetwork',
    text: 'Medium',
    desc: 'medium',
  },
  {
    darkICon: DISCORD_DARK,
    icon: DISCORD,
    url: 'https://discord.com/invite/7UYuwuutpc',
    text: 'Discord',
    desc: 'discord',
  },
]

const CommunityLinkGroup: React.FC = () => {
  const { darkMode } = useContext(ThemeContext)

  const handleClick = (data: any) => {
    window.open(data.url)
    handleEvent(`home_nav_${data.desc}`)
  }
  return (
    <Wrapper>
    {imgArray.map(item => (
      <div className='item' onClick={() => handleClick(item)} key={item.url}>
        <img src={darkMode ? item.darkICon : item.icon}/>
        <div className='text'>{item.text}</div>
      </div>
    ))}
    </Wrapper>
  )
}

export default CommunityLinkGroup
