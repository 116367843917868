import styled from 'styled-components'
import { Autoplay, Mousewheel, Pagination } from 'swiper'
import { Swiper as RSwiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import BULLET from './images/polygon.svg'
import BULLET_ACTIVE from './images/polygon-active.svg'
import Button from '../../components/Button'
import MARKET from './images/market.png'
import MERGE from './images/merge.png'
import PRO from './images/protocol.png'
import TRANTOR from './images/trantor-bg.jpeg'
import FRIST from './images/frist.png'
import LAST from './images/last.jpeg'
import { defaultTheme, flexCenter, primaryLinear } from '../../style'
import MEDIUM from './images/medium.svg'
import TWITTER from './images/twitter.svg'
import DISCORD from './images/discord.svg'
import DOCS from './images/docs.svg'
import { useAppSelector } from '../../helpers/hooks'
import { Link } from 'react-router-dom'
import { handleEvent } from '../../Reporter'

const Wrapper = styled.div`
  background-color: #000;
  h2 {
    color: #fff;
    font-size: ${prop => prop.theme.isDesktop ? '24px' : '16px'};
    font-weight: 400;
    margin: 0;
  }
  font-family: SFRegular;
`
const Slide = styled(SwiperSlide)`
  height: 100%;
  color: #fff;
  overflow: hidden;
  .container {
    width: ${prop => (prop.theme.isDesktop ? 'calc(100% - 212px)' : 'calc(100% - 40px)')};
    margin: 0 auto;
  }
  .head {
    margin-top: ${prop => (prop.theme.isDesktop ? '110px' : '20px')};
  }
  .market {
    position: ${prop => (prop.theme.isDesktop ? 'absolute' : 'relative')};
    right: ${prop => (prop.theme.isDesktop ? '40px' : '0')};
    bottom: ${prop => (prop.theme.isDesktop ? '-200px' : '-46px')};
    width: ${prop => (prop.theme.isDesktop ? '60vw' : '439px')};
    height: auto;
    z-index: -1;
  }
  .title {
    color: #fff;
    font-size: ${prop => prop.theme.isDesktop ? '64px' : '28px'};
    font-family: SFHeavy;
    font-weight: 800;
    line-height: ${prop => (prop.theme.isDesktop ? '76px' : '33px')};
    text-transform: uppercase;
    .bold {
      color: #FCFF00;
    }
  }
  .sec-title {
    margin-top: ${prop => (prop.theme.isDesktop ? '31px' : '14px')};
    margin-bottom: ${prop => (prop.theme.isDesktop ? '69px' : '24px')};
  }
  .desc {
    font-size: ${prop => prop.theme.fontNormal};
    margin-top: ${prop => (prop.theme.isDesktop ? '30px' : '24px')};
    margin-bottom: ${prop => (prop.theme.isDesktop ? '70px' : '43px')};
    width: ${prop => (prop.theme.isDesktop ? '530px' : 'auto')};
    line-height: 22px;
    color: ${prop => prop.theme.grey2};
  }
  .text {
    color: #fff;
    font-weight: 800;
    font-size: ${prop => prop.theme.fontLarge};
    line-height: 26px;
    margin: 26px;
  }
  .center {
    width: ${prop => (prop.theme.isDesktop ? 'calc(100% - 212px)' : 'calc(100% - 40px)')};
    margin: 0 auto;
    flex-direction: ${prop => (prop.theme.isDesktop ? 'row' : 'column')};
    align-items: center;
    justify-content: center;
    height: 100%;
    .content {
      width: ${prop => (prop.theme.isDesktop ? '50%' : '100%')};
    }
    .merge-content {
      width: ${prop => (prop.theme.isDesktop ? '560px' : 'auto')};
      margin: 0 auto;
    }
    .protocol-content {
      width: ${prop => (prop.theme.isDesktop ? '507px' : 'auto')};
      margin: 0 auto;
    }
    .merge {
      width: ${prop => (prop.theme.isDesktop ? '546px' : '256px')};
      height: auto;
      margin: 0 auto;
      z-index: -1;
      ${prop =>
        !prop.theme.isDesktop &&
        `
        margin-bottom: 50px;
      `}
    }
    .protocol {
      width: ${prop => (prop.theme.isDesktop ? '562px' : '305px')};
      height: auto;
      margin: 0 auto;
      ${prop => !prop.theme.isDesktop && 'margin-top: 30px'}
    }
  }
  .reverse {
    ${prop => !prop.theme.isDesktop && 'flex-direction: column-reverse'};
  }
  .row {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .item {
    ${flexCenter};
    flex-direction: row;
  }
  .bg-container {
    ${flexCenter};
    height: 100%;
    ${prop =>
      !prop.theme.isDesktop &&
      `
      width: calc(100% - 40px);
      margin: 0 auto;
    `};
  }
  .title-center {
    ${prop =>
      !prop.theme.isDesktop &&
      `
      text-align: center;
  `};
  }
  .bg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    ${flexCenter};
    height: 100%;
    background-image: url(${TRANTOR});
    background-size: 1920px auto;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .frist {
    background-image: url(${FRIST});
    opacity: 0;
    background-size: 100% 100%;
    transition: 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .last {
    background-image: url(${LAST});
    opacity: 0.3;
    transition: 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
    background-position: bottom;
    ${prop =>
      !prop.theme.isDesktop &&
      `
      background-size: 850px auto;
    `}
  }
  .margin {
    margin-top: 127px;
  }
  .protocol-title {
    width: ${prop => (prop.theme.isDesktop ? '486px' : 'auto')};
  }
  .protocol-desc {
    margin-top: 24px;
    margin-bottom: 0;
    width: ${prop => (prop.theme.isDesktop ? '486px' : 'auto')};
  }
  .bg-desc {
    width: ${prop => (prop.theme.isDesktop ? '590px' : 'calc(100% - 40px)')};
    text-align: center;
  }
  .last-desc {
    width: ${prop => (prop.theme.isDesktop ? '762px' : 'auto')};
    text-align: center;
  }
  @media (min-width: 1024px) and (max-width: 1440px) {
    .title {
      font-size: 48px;
      line-height: 56px;
    }
    h2 {
      font-size: 20px;
      line-height: 28px;
    }
    .desc {
      font-size: 16px;
      line-height: 24px;
      width: auto;
    }
    .bg-desc,
    .last-desc {
      width: 585px;
    }
    .text {
      font-size: 18px;
      line-height: 26px;
    }
    .head {
      margin-top: 130px;
    }
    .market {
      right: 40px;
      bottom: -170px;
    }
    .center {
      .merge {
        width: 348px;
      }
      .protocol {
        width: 400px;
      }
    }
    .protocol-desc .protocol-title {
      width: 380px;
    }
  }
  @media (min-width: 1880px) {
    .market {
      bottom: -363px;
    }
    .head {
      margin-top: 210px;
    }
  }
`
const Swiper = styled(RSwiper)`
  width: 100%;
  height: 100vh;
  .swiper-pagination-vertical {
    right: 90px !important;
    ${prop => !prop.theme.isDesktop && `display: none`}
  }
  .swiper-pagination-bullet {
    background-color: transparent;
    opacity: 1;
    ::after {
      content: url(${BULLET});
      width: 16px;
      height: 16px;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: transparent;
    ::after {
      margin-left: -2px;
      content: url(${BULLET_ACTIVE});
      width: 16px;
      height: 16px;
    }
  }
  .swiper-slide-active {
    .frist,
    .last {
      opacity: 1;
      transition-delay: 0.5s;
    }
  }
`
const TSwiper = styled(RSwiper)`
  height: ${prop => (prop.theme.isDesktop ? '76px' : '28px')};
  width: ${prop => (prop.theme.isDesktop ? '278px' : '160px')};
  @media (min-width: 1024px) and (max-width: 1440px) {
    height: 56px;
  }
`
const Community = styled.div`
  ${flexCenter};
  flex-direction: row;
  position: absolute;
  bottom: 48px;
  img {
    width: 44px;
    height: 44px;
  }
`
const TSlide = styled(SwiperSlide)`
  height: 100%;
  width: 278px;
  font-size: ${prop => prop.theme.isDesktop ? '64px' : '28px'};
  font-family: SFBold;
  font-weight: 800;
  line-height: ${prop => (prop.theme.isDesktop ? '76px' : '28px')};
  text-transform: uppercase;
  background-image: ${primaryLinear};
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  @media (min-width: 1024px) and (max-width: 1440px) {
    font-size: 48px;
    line-height: 56px;
  }
`
export const DISCORD_LINK = 'https://discord.gg/7UYuwuutpc'
export const DOCS_LINK = 'https://docs.trantor.network/'
export const TWITTER_LINK = 'https://twitter.com/TrantorNetwork'
const Market: React.FC = () => {
  const isDesktop = useAppSelector(state => state.app.isDesktop)

  return (
    <Wrapper>
      <Swiper
        direction={'vertical'}
        slidesPerView={1}
        spaceBetween={30}
        mousewheel
        pagination={{
          clickable: true,
        }}
        keyboard
        scrollbar={{ draggable: true }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper"
      >
        <Slide>
          <div className="container">
            <img src={MARKET} className="market" />
            <div className="content head">
              <div className="title">
                The First <br />
                <span className="bold">NFT Omni-Liquidity</span>
                Aggregation Market
              </div>
              <h2 className="sec-title">Purchase NFTs From All Chains In One</h2>
              <Button awesomeButton text="Coming Soon" />
            </div>
          </div>
        </Slide>
        <Slide>
          <div className="bg frist" />
          <div className="bg-container">
            <div className="row">
              <div className="item">
                <div className="title">Buy From</div>
                <TSwiper
                  direction={'vertical'}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                >
                  <TSlide>bsc</TSlide>
                  <TSlide>aptos</TSlide>
                  <TSlide>arb</TSlide>
                  <TSlide>op</TSlide>
                  <TSlide>eth</TSlide>
                  <TSlide>sui</TSlide>
                  <TSlide>near</TSlide>
                  <TSlide>poly</TSlide>
                </TSwiper>
              </div>
              <div className="item">
                <div className="title">on</div>
                <TSwiper
                  direction={'vertical'}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                >
                  <TSlide>eth</TSlide>
                  <TSlide>bsc</TSlide>
                  <TSlide>aptos</TSlide>
                  <TSlide>near</TSlide>
                  <TSlide>poly</TSlide>
                  <TSlide>op</TSlide>
                  <TSlide>sui</TSlide>
                  <TSlide>arb</TSlide>
                </TSwiper>
              </div>
            </div>
            <div className="title margin">all in one</div>
          </div>
        </Slide>
        <Slide>
          <div className="center">
            <div className="content">
              <div className="merge-content">
                <div className="title">Omni-Liquidity</div>
                <div className="desc">
                  Trantor empowers your NFT orders with Omni-Liquidity and helps NFT sellers find
                  more potential buyers on all chains, not just a single chain.
                </div>
                {isDesktop && (
                  <a
                    href={TWITTER_LINK}
                    className="link"
                    target="_blank"
                    onClick={() => handleEvent('home_btn_learnmore')}
                    rel="noreferrer"
                  >
                    <Button awesomeButton text="Learn more" />
                  </a>
                )}
              </div>
            </div>
            <div className="content">
              <img src={MERGE} className="merge" />
              {!isDesktop && (
                <a
                  href={TWITTER_LINK}
                  className="link"
                  target="_blank"
                  onClick={() => handleEvent('home_btn_learnmore')}
                  rel="noreferrer"
                >
                  <Button awesomeButton text="Learn more" />
                </a>
              )}
            </div>
          </div>
        </Slide>
        <Slide>
          <div className="center reverse">
            <img src={PRO} className="protocol" />
            <div className="content">
              <div className="protocol-content">
                <div className="title protocol-title">Aggregation Market</div>
                <div className="desc protocol-desc">
                  Trantor aggregates NFT orders from all open and on-chain NFT marketplaces and
                  provides accessible Omni-Liquidity to everyone in a permissionless manner.
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <Slide>
          <div className="bg last" />
          <div className="bg-container ">
            <div className="title title-center">Looking forward to using it?</div>
            <div className="text">{`Let's Build It!`}</div>
            <a
              href={DISCORD_LINK}
              className="link"
              target="_blank"
              onClick={() => handleEvent('home_btn_joinus')}
              rel="noreferrer"
            >
              <Button awesomeButton text="Join us" variant="outlined" />
            </a>
          </div>
        </Slide>
      </Swiper>
    </Wrapper>
  )
}

export default Market
