import { useContext } from 'react'
import styled from 'styled-components'
import MOON from './images/moon.png'
import SUN from './images/sun.png'

import { ThemeContext } from '../../../helpers/hooks'

const Wrapper = styled.div`
  cursor: ${prop => (prop.theme.isDesktop ? 'pointer' : 'none')};
  img {
    width: 29px;
    margin-left: 10px;
  }
`

const ThemeSwitchIcon: React.FC = () => {
  const { darkMode, setDarkMode } = useContext(ThemeContext)
  return (
    <Wrapper>
      <img src={darkMode ? SUN : MOON} alt="" onClick={() => setDarkMode(pre => !pre)} />
    </Wrapper>
  )
}

export default ThemeSwitchIcon
