import { Link } from "react-router-dom"
import styled from "styled-components"
import { handleEvent } from "../../../Reporter"
import Button from "../../Button/Button"
import Tooltip from "../../Tooltip"

const Wrapper = styled.div`
    position: absolute;
    left: calc(50% - 148px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 297px;
    height: 48px;
    color: #fff;
    font-family: 'SFRegular';
    a {
      padding: 11px 8px;
      border-radius: 8px;
    }
    a:hover {
      text-decoration: underline;
    }
`

const HomeTab: React.FC = () => {

  return  (
    <Wrapper>
      <Link to="/bridge" onClick={() => handleEvent('Nav_Bridge')}><div>Bridge</div></Link>
      <Link to="/market" >
        <span onClick={() => handleEvent('Nav_market')}>Market</span>
      </Link>
      <Tooltip text="coming soon" placement="bottom" arrow isDashboard>
        <a onClick={() => handleEvent('home_nav_dashboard')}>Dashboard</a>
      </Tooltip>
    </Wrapper>
  )
}

export default HomeTab
